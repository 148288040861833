import React from 'react';
import styled from 'styled-components';
import Colors from '../../colors';

export const FormContainer = styled.div`
  background: linear-gradient(
    180deg,
    ${Colors.accent.goBigGoHomeDarkBlue.standard} 0%,
    ${Colors.accent.goBigGoHomeLightBlue.standard} 100%
  );

  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 1025px) {
    max-width: none;
    width: 50%;
    margin: unset;
  }

  @media screen and (min-width: 1600px) {
    justify-content: center;
  }

  .form-headline {
    box-sizing: border-box;
    text-align: center;
    max-width: 346px;
    margin-top: 47px;

    @media only screen and (min-width: 768px) {
      max-width: 596px;
    }

    h1 {
      font-weight: 900;
      font-family: 'acumin-pro', sans-serif;
      color: ${Colors.primary.white.standard};
      margin-block-start: 0;
      margin-block-end: 0;
      font-size: 65px;
      line-height: 60px;

      @media only screen and (min-width: 768px) {
        font-size: 120px;
        line-height: 100px;
      }
    }

    h2 {
      font-weight: 900;
      font-family: 'acumin-pro', sans-serif;
      color: ${Colors.primary.white.standard};
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 4px 0;
      font-size: 30px;
      line-height: 35px;
      text-transform: uppercase;

      @media only screen and (min-width: 768px) {
        font-size: 60px;
        line-height: 60px;
      }
    }

    .divider {
      background: ${Colors.primary.white.standard};
      width: 64px;
      margin: 32px 0;
      height: 1px;
    }
  }

  .promo-disclaimer {
    font-weight: 400;
    font-family: 'source-sans-pro', sans-serif;
    color: ${Colors.primary.white.standard};
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    margin-top: 20px;
    a {
      cursor: pointer;
      text-decoration: underline;
      font-weight: 400;
      font-family: 'source-sans-pro', sans-serif;
      color: ${Colors.primary.white.standard};
      margin-block-start: 0;
      margin-block-end: 0;
      font-size: 10px;
      line-height: 13px;
    }
  }

  .form-offer-text {
    font: 400 20px/24px 'acumin-pro', sans-serif;
    letter-spacing: -0.35px;
    color: ${Colors.primary.white.standard};
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 17px auto;
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .group {
    @media only screen and (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }
  }

  form {
    margin: 0 auto;
    padding: 20px 0 47px;
    max-width: 496px;
    width: 87%;

    @media only screen and (min-width: 768px) {
      width: 69%;
    }
  }

  .input-container {
    position: relative;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      width: 100%;

      input {
        max-width: none;
      }
    }
  }

  .half-width-input {
    @media only screen and (min-width: 768px) {
      width: 47%;
    }
  }

  .full-width-input {
    width: 100%;
  }

  .error-message {
    color: ${Colors.primary.white.standard};
    font-size: 10px;
    margin-top: 2px;
  }

  .no-margin {
    margin: 0;
  }

  .submit-btn {
    width: 100%;
    background-color: ${Colors.accent.goBigGoHomeRed.standard};
    color: ${Colors.primary.white.standard};
    border: none;
    font-weight: 700;

    &:hover {
      opacity: 0.8;
      background-color: ${Colors.accent.goBigGoHomeRed.standard} !important;
    }
  }

  .agree-terms {
    font: 400 12px/17px 'source-sans-pro', sans-serif;
    color: ${Colors.primary.white.standard};
    width: 100%;
    margin: 0 0 17px;

    span {
      font: 400 12px/17px 'source-sans-pro', sans-serif;
    }

    .capitalize-first-letter {
      text-transform: capitalize;
    }

    a {
      text-decoration: underline;
      color: ${Colors.primary.white.standard};
      font-family: 'source-sans-pro', sans-serif;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
