import React from 'react';
import styled from 'styled-components';
import Colors from '../../colors';

export const StyledBanner = styled.div`
  width: 100%;
  background-color: ${Colors.accent.bedtime.standard};

  @media only screen and (min-width: 1025px) {
    display: flex;
    justify-content: space-between;
  }

  .banner {
    background-color: ${Colors.accent.bedtime.standard};
    position: relative;

    @media only screen and (min-width: 1025px) {
      height: auto;
      width: 50%;
    }

    .banner-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .go-big-go-home-logo {
      top: 0;
      left: 0;
      position: absolute;
      width: 249px;

      @media screen and (min-width: 768px) {
        width: 248px;
        right: 0;
        left: auto;
      }

      @media screen and (min-width: 1025px) {
        width: 424px;
        left: 0;
        right: auto;
      }
    }

    .have-it-made-banner {
      display: block;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }

    .have-it-made-banner-tablet {
      display: none;
      @media only screen and (max-width: 1024px) {
        display: block;
      }
      @media only screen and (max-width: 580px) {
        display: none;
      }
    }
    .have-it-made-banner-mobile {
      display: none;
      @media only screen and (max-width: 580px) {
        display: block;
      }
    }
  }
`;
