import React from 'react';
import styled from 'styled-components';

const GoBigGoHomeHouseConfirmation = ({}) => {
  const Logo = styled.svg`
    height: auto;

    @media only screen and (min-width: 320px) {
      max-width: 120px;
    }

    @media only screen and (min-width: 768px) {
      max-width: 150px;
    }

    @media only screen and (min-width: 1025px) {
      max-width: 181px;
      min-height: 176px;
    }
  `;

  return (
    <Logo
      className="go-big-go-home-house"
      viewBox="0 0 181 176"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M16.1651 50.1259H73.8786C77.7839 50.1259 80.8214 46.957 80.8214 43.212V6.91392C80.8214 3.02483 77.6394 0 73.8786 0H16.1651C12.2597 0 9.22217 3.1689 9.22217 6.91392V43.068C9.22217 46.9571 12.4044 50.1259 16.1651 50.1259Z"
        fill="#5AB0E4"
      />
      <path
        d="M35.6922 47.245C35.6922 47.245 28.7493 50.846 27.7368 56.6075C27.4475 57.9039 25.7118 58.4802 24.8439 57.4718C22.5296 55.1672 19.7812 50.5579 19.7812 42.0596"
        fill="#5AB0E4"
      />
      <path
        d="M29.4728 29.5281C31.9493 29.5281 33.9568 27.529 33.9568 25.0629C33.9568 22.5968 31.9493 20.5977 29.4728 20.5977C26.9964 20.5977 24.9888 22.5968 24.9888 25.0629C24.9888 27.529 26.9964 29.5281 29.4728 29.5281Z"
        fill="white"
      />
      <path
        d="M45.0939 29.5281C47.5703 29.5281 49.5779 27.529 49.5779 25.0629C49.5779 22.5968 47.5703 20.5977 45.0939 20.5977C42.6175 20.5977 40.6099 22.5968 40.6099 25.0629C40.6099 27.529 42.6175 29.5281 45.0939 29.5281Z"
        fill="white"
      />
      <path
        d="M60.7159 29.5281C63.1923 29.5281 65.1999 27.529 65.1999 25.0629C65.1999 22.5968 63.1924 20.5977 60.7159 20.5977C58.2395 20.5977 56.2319 22.5968 56.2319 25.0629C56.232 27.529 58.2395 29.5281 60.7159 29.5281Z"
        fill="white"
      />
      <path
        d="M42.4355 143.797C29.8757 146.109 17.5613 149.737 5.76206 154.601C2.98157 155.748 -0.218487 157.566 0.0117385 160.716C0.114676 162.124 0.958021 163.378 2.12177 164.187C4.40262 165.774 7.1609 165.743 9.80923 165.693C35.369 165.211 60.9287 164.729 86.4884 164.247C88.6251 164.207 89.2707 167.136 87.3041 167.968C85.5813 168.697 83.9472 169.533 83.6836 171.201C83.5119 172.288 84.0476 173.366 84.911 174.053C86.3776 175.221 88.2485 175.304 90.0396 175.366C114.615 176.228 139.51 177.062 163.506 171.713C167.69 170.78 171.939 169.612 175.402 167.097C178.864 164.582 181.423 160.441 180.942 156.203C180.543 152.691 178.131 149.648 175.179 147.68C172.228 145.711 168.765 144.665 165.335 143.741C132.442 134.878 97.8862 134.856 63.8072 134.894"
        fill="#5AB0E4"
      />
      <path
        d="M144.095 75.8916H22.3096V79.3661H144.095V75.8916Z"
        fill="#0080C7"
      />
      <path
        d="M113.68 34.5806L57.8875 75.8915H22.3096L77.653 34.5806H113.68Z"
        fill="#0080C7"
      />
      <path
        d="M108.903 38.0552H77.8631V61.0263L59.9385 74.2914V157.118H157.868V74.2914L108.903 38.0552Z"
        fill="#DAF1FF"
      />
      <path
        d="M77.8633 38.0552L28.8984 74.2914V157.118H126.828V74.2914L77.8633 38.0552Z"
        fill="white"
      />
      <path
        d="M91.1243 102.174H64.6016V157.118H91.1243V102.174Z"
        fill="#0080C7"
      />
      <path
        d="M157.868 76.8498L113.89 44.0224H116.967L108.903 38.0552H77.8633V44.0225V44.0224L133.656 85.3333H157.868V76.8498Z"
        fill="#B2E2FF"
      />
      <path
        d="M77.8628 34.5806L133.655 75.8915H169.233L113.89 34.5806H77.8628Z"
        fill="#8AD1FC"
      />
      <path
        d="M77.8628 34.5806V38.0551L133.655 79.366V75.8915L77.8628 34.5806Z"
        fill="#0080C7"
      />
      <path
        d="M169.233 75.8916H133.655V79.3661H169.233V75.8916Z"
        fill="#0080C7"
      />
      <path
        d="M28.8984 74.2914V80.278L77.8634 44.0224L126.828 80.278V74.2914L77.8634 38.0552L28.8984 74.2914Z"
        fill="#DAF1FF"
      />
      <path
        d="M114.354 105.059H103.724V128.105H114.354V105.059Z"
        fill="#DAF1FF"
      />
      <path
        d="M121.006 102.174H114.354V128.104H121.006V102.174Z"
        fill="#0080C7"
      />
      <path
        d="M103.724 102.174H97.0728V128.104H103.724V102.174Z"
        fill="#0080C7"
      />
      <path
        d="M119.867 103.73H115.464V114.318H119.867V103.73Z"
        stroke="#8AD1FC"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.6 103.73H98.1973V114.318H102.6V103.73Z"
        stroke="#8AD1FC"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.867 115.958H115.464V126.547H119.867V115.958Z"
        stroke="#8AD1FC"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.6 115.958H98.1973V126.547H102.6V115.958Z"
        stroke="#8AD1FC"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.724 118.93V102.174H111.108V111.586C111.108 115.642 107.802 118.93 103.724 118.93Z"
        fill="#8AD1FC"
      />
      <path
        d="M114.354 118.93V102.174H106.971V111.586C106.971 115.642 110.276 118.93 114.354 118.93Z"
        fill="#8AD1FC"
      />
      <path
        d="M148.083 105.059H137.453V128.105H148.083V105.059Z"
        fill="#B2E2FF"
      />
      <path
        d="M154.734 102.174H148.083V128.104H154.734V102.174Z"
        fill="#0080C7"
      />
      <path
        d="M137.453 102.174H130.801V128.104H137.453V102.174Z"
        fill="#0080C7"
      />
      <path
        d="M153.595 103.73H149.192V114.318H153.595V103.73Z"
        stroke="#8AD1FC"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.328 103.73H131.925V114.318H136.328V103.73Z"
        stroke="#8AD1FC"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.595 115.958H149.192V126.547H153.595V115.958Z"
        stroke="#8AD1FC"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.328 115.958H131.925V126.547H136.328V115.958Z"
        stroke="#8AD1FC"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.453 118.93V102.174H144.836V111.586C144.836 115.642 141.531 118.93 137.453 118.93Z"
        fill="#8AD1FC"
      />
      <path
        d="M148.082 118.93V102.174H140.699V111.586C140.699 115.642 144.004 118.93 148.082 118.93Z"
        fill="#8AD1FC"
      />
      <path d="M52.27 103.73H41.6401V128.105H52.27V103.73Z" fill="#DAF1FF" />
      <path
        d="M58.9215 102.174H52.27V128.104H58.9215V102.174Z"
        fill="#0080C7"
      />
      <path
        d="M41.6403 102.174H34.9888V128.104H41.6403V102.174Z"
        fill="#0080C7"
      />
      <path
        d="M57.7829 103.73H53.3799V114.318H57.7829V103.73Z"
        stroke="#8AD1FC"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.5158 103.73H36.1128V114.318H40.5158V103.73Z"
        stroke="#8AD1FC"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.7829 115.958H53.3799V126.547H57.7829V115.958Z"
        stroke="#8AD1FC"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.5158 115.958H36.1128V126.547H40.5158V115.958Z"
        stroke="#8AD1FC"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.6401 118.93V102.174H49.0237V111.586C49.0237 115.642 45.718 118.93 41.6401 118.93Z"
        fill="#8AD1FC"
      />
      <path
        d="M52.2703 118.93V102.174H44.8867V111.586C44.8867 115.642 48.1925 118.93 52.2703 118.93Z"
        fill="#8AD1FC"
      />
      <path
        d="M91.1243 91.4194H64.6016V97.5867H91.1243V91.4194Z"
        fill="#8AD1FC"
      />
      <path
        d="M87.388 132.77C88.274 132.77 88.9922 132.056 88.9922 131.175C88.9922 130.293 88.274 129.579 87.388 129.579C86.5019 129.579 85.7837 130.293 85.7837 131.175C85.7837 132.056 86.5019 132.77 87.388 132.77Z"
        fill="white"
      />
      <path
        d="M32.4277 81.4429H123.298"
        stroke="#0080C7"
        strokeMiterlimit="10"
      />
      <path
        d="M76.3017 106.186H67.9946V125.475H76.3017V106.186Z"
        stroke="#B2E2FF"
        strokeMiterlimit="10"
      />
      <path
        d="M87.3881 106.186H79.0811V125.475H87.3881V106.186Z"
        stroke="#B2E2FF"
        strokeMiterlimit="10"
      />
      <path
        d="M77.8633 71.2853C81.6921 71.2853 84.796 68.1982 84.796 64.39C84.796 60.5818 81.6921 57.4946 77.8633 57.4946C74.0345 57.4946 70.9307 60.5818 70.9307 64.39C70.9307 68.1982 74.0345 71.2853 77.8633 71.2853Z"
        stroke="#0080C7"
        strokeMiterlimit="10"
      />
      <path
        d="M109.878 147.42C109.878 147.42 105.631 150.052 107.974 157.338"
        stroke="#0080C7"
        strokeMiterlimit="10"
      />
      <path
        d="M103.501 145.994C103.501 145.994 107.09 148.49 107.18 156.779"
        stroke="#0080C7"
        strokeMiterlimit="10"
      />
      <path
        d="M111.228 149.343C109.272 150.591 107.792 152.557 107.138 154.775C106.058 152.992 104.17 151.715 102.107 151.371C101.923 151.34 103.607 155.514 103.897 155.905C104.675 156.952 106.271 158.781 107.731 158.725C110.96 158.6 111.492 151.549 111.228 149.343Z"
        fill="#8AD1FC"
      />
      <path
        d="M110.032 148.044C110.725 148.044 111.287 147.486 111.287 146.797C111.287 146.107 110.725 145.549 110.032 145.549C109.339 145.549 108.777 146.107 108.777 146.797C108.777 147.486 109.339 148.044 110.032 148.044Z"
        fill="#8AD1FC"
      />
      <path
        d="M103.502 147.242C104.195 147.242 104.756 146.684 104.756 145.994C104.756 145.305 104.195 144.747 103.502 144.747C102.809 144.747 102.247 145.305 102.247 145.994C102.247 146.684 102.809 147.242 103.502 147.242Z"
        fill="#8AD1FC"
      />
      <path
        d="M50.143 147.42C50.143 147.42 45.8965 150.052 48.2392 157.338"
        stroke="#0080C7"
        strokeMiterlimit="10"
      />
      <path
        d="M43.7671 145.994C43.7671 145.994 47.3559 148.49 47.4455 156.779"
        stroke="#0080C7"
        strokeMiterlimit="10"
      />
      <path
        d="M51.4932 149.344C49.5378 150.592 48.0573 152.558 47.4032 154.775C46.3234 152.993 44.436 151.716 42.3724 151.371C42.1886 151.341 43.8724 155.514 44.1628 155.905C44.9401 156.952 46.5367 158.782 47.9962 158.725C51.2256 158.601 51.7578 151.549 51.4932 149.344Z"
        fill="#8AD1FC"
      />
      <path
        d="M50.2975 148.044C50.9904 148.044 51.5521 147.486 51.5521 146.797C51.5521 146.107 50.9904 145.549 50.2975 145.549C49.6047 145.549 49.043 146.107 49.043 146.797C49.043 147.486 49.6047 148.044 50.2975 148.044Z"
        fill="#8AD1FC"
      />
      <path
        d="M43.7673 147.242C44.4602 147.242 45.0219 146.684 45.0219 145.994C45.0219 145.305 44.4602 144.747 43.7673 144.747C43.0744 144.747 42.5127 145.305 42.5127 145.994C42.5127 146.684 43.0744 147.242 43.7673 147.242Z"
        fill="#8AD1FC"
      />
      <path
        d="M145.955 147.42C145.955 147.42 141.708 150.052 144.051 157.338"
        stroke="#0080C7"
        strokeMiterlimit="10"
      />
      <path
        d="M139.58 145.994C139.58 145.994 143.168 148.49 143.258 156.779"
        stroke="#0080C7"
        strokeMiterlimit="10"
      />
      <path
        d="M147.305 149.343C145.35 150.591 143.869 152.557 143.215 154.775C142.135 152.992 140.248 151.715 138.184 151.371C138.001 151.34 139.684 155.514 139.975 155.905C140.752 156.952 142.349 158.781 143.808 158.725C147.038 158.6 147.57 151.549 147.305 149.343Z"
        fill="#8AD1FC"
      />
      <path
        d="M146.11 148.044C146.802 148.044 147.364 147.486 147.364 146.797C147.364 146.107 146.802 145.549 146.11 145.549C145.417 145.549 144.855 146.107 144.855 146.797C144.855 147.486 145.417 148.044 146.11 148.044Z"
        fill="#8AD1FC"
      />
      <path
        d="M139.579 147.242C140.272 147.242 140.834 146.684 140.834 145.994C140.834 145.305 140.272 144.747 139.579 144.747C138.886 144.747 138.325 145.305 138.325 145.994C138.325 146.684 138.886 147.242 139.579 147.242Z"
        fill="#8AD1FC"
      />
      <path
        d="M172.055 99.0235H127.768C124.767 99.0235 122.481 96.605 122.481 93.7596V65.8757C122.481 62.8882 124.91 60.6118 127.768 60.6118H172.055C175.055 60.6118 177.341 63.0303 177.341 65.8757V93.6174C177.341 96.6049 175.056 99.0235 172.055 99.0235Z"
        fill="#5AB0E4"
      />
      <path
        d="M157.055 96.8895C157.055 96.8895 162.484 99.7347 163.199 104.145C163.341 105.141 164.77 105.568 165.485 104.856C167.199 103.007 169.342 99.5925 169.342 93.0483"
        fill="#5AB0E4"
      />
      <path
        d="M137.911 84.0856C139.804 84.0856 141.34 82.5569 141.34 80.6712C141.34 78.7855 139.804 77.2568 137.911 77.2568C136.017 77.2568 134.483 78.7855 134.483 80.6712C134.483 82.5569 136.017 84.0856 137.911 84.0856Z"
        fill="white"
      />
      <path
        d="M149.912 84.0856C151.805 84.0856 153.34 82.5569 153.34 80.6712C153.34 78.7855 151.805 77.2568 149.912 77.2568C148.018 77.2568 146.483 78.7855 146.483 80.6712C146.483 82.5569 148.018 84.0856 149.912 84.0856Z"
        fill="white"
      />
      <path
        d="M161.912 84.0856C163.806 84.0856 165.34 82.5569 165.34 80.6712C165.34 78.7855 163.806 77.2568 161.912 77.2568C160.019 77.2568 158.483 78.7855 158.483 80.6712C158.483 82.5569 160.019 84.0856 161.912 84.0856Z"
        fill="white"
      />
    </Logo>
  );
};

export default GoBigGoHomeHouseConfirmation;
