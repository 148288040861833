import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import App from '../../App';
import VideoBackgroundImage from '../../images/promo-images/gbgh-video-thumbnail.webp';
import ExploreMoreWaysSaveBackground from '../../images/explore-more-ways-save-background-gbgh.webp';
import ExploreMoreWaysSaveTabletBackground from '../../images/explore-more-ways-save-background-gbgh-tablet.webp';
import ExploreMoreWaysSaveMobileBackground from '../../images/explore-more-ways-save-background-gbgh-mobile.webp';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { parseInitialPropsContext } from '../../utils';
import HouseSmartPromoGrid from '../Shared/HouseSmartPromoGrid/HouseSmartPromoGrid';
import SaleHomeSlider from '../Shared/SaleHomeSlider/SaleHomeSlider';
import { smoothScroll } from '../Shared/SmokeyTransitional/utils/utils';
import TextContent from '../Shared/TextContent/TextContent';
import Video from '../Shared/Video/Video';
import XLCTA from '../Shared/XLCTA/XLCTA';
import Banner from './Banner';
import ExploreWaysToSaveBanner from '../Shared/ExploreWaysToSaveBanner/ExploreWaysToSaveBanner';
import FeaturedEvent from '../Shared/FeaturedEvent/FeaturedEvent';
import PromoContent from '../Promo/PromoContent';
import PromoDisclaimer from './PromoDisclaimer';
import WinterPromoStyles from './WinterPromo.styled';
import { getNewsAndEvents } from '../../Api.js';
import Colors from '../../colors.js';

const promoIsInactive = false;

const WinterPromo = ({
  dealer,
  homes,
  homesOnSale,
  featuredEvent,
  isLoading,
}) => {
  const location = useLocation();
  const history = useHistory();
  const hash = location.hash;

  const [showTy, setShowTy] = useState(false);

  const disclaimerRef = useRef(null);

  const dealerBrand = dealer?.dealerName.split(' ');

  const showThankYou = () => {
    setShowTy(true);
  };

  const isClayton = () => {
    if (dealer?.brandAcronym.toLowerCase() === 'cla') {
      return true;
    }
    return false;
  };

  const handleScroll = () => {
    smoothScroll('#PromoDisclaimer', -90);
  };

  useLayoutEffect(() => {
    if (hash !== '') {
      setTimeout(() => {
        const id = hash?.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 800);
    }
  }, []);

  if (dealer?.websiteTemplateDescription === 'BuilderModel') {
    return <Redirect to="/" />;
  }

  if (
    promoIsInactive ||
    dealer?.websiteTemplateDescription === 'NoSpecialOffers'
  ) {
    return <Redirect to="/special-offers" />;
  }

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <WinterPromoStyles>
        <Helmet>
          <title>
            {' '}
            Go Big and Go Home Sales Event - Affordable Modern Manufactured,
            Mobile Homes | {formatDealerName(dealer?.dealerName)}
          </title>
          <meta
            name="description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
          <meta
            name="og:description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
        </Helmet>
        <Banner
          formHeadline={'$150,000*'}
          formSubheadline={
            <>
              NEW HOMES
              <br /> STARTING UNDER
            </>
          }
          formTopDisclaimer={
            <>
              *Not including required taxes, delivery & installation. Sale will
              vary by retailer & state. Only available on select models. For
              complete pricing details, <a onClick={handleScroll}>click here</a>
              .
            </>
          }
          formText={
            'Complete this form to receive information about sale homes and future offers.'
          }
          formDisclaimer={
            <>
              By submitting my information, I agree that{' '}
              {isClayton() ? (
                'Clayton'
              ) : (
                <span className="capitalize-first-letter">
                  {dealerBrand[0].toLowerCase()}
                </span>
              )}{' '}
              Homes and its service providers may contact me and may send me
              marketing communications by telephone and text message using
              automated dialing technology to the mobile number I provided in
              this form. See our{' '}
              <a
                href="https://www.claytonhomes.com/account-terms-conditions"
                target="_blank"
              >
                Terms &amp; Conditions
              </a>
              , which includes opt-out instructions. Message and data rates may
              apply. I also agree that{' '}
              {isClayton() ? (
                'Clayton'
              ) : (
                <span className="capitalize-first-letter">
                  {dealerBrand[0].toLowerCase()}
                </span>
              )}{' '}
              Homes and its service providers may send me marketing e-mails,
              which I may unsubscribe from at any time. Consent not required for
              purchase. Please view our{' '}
              <a href="https://www.claytonhomes.com/privacy/" target="_blank">
                Privacy Policies
              </a>
              .
            </>
          }
          dealer={dealer}
          homes={homes}
          showThankYouCallBack={showThankYou}
          showTy={showTy}
          isClayton={isClayton}
        />
        <TextContent
          smallHeadline={'Go big & go home sale'}
          largeHeadline={
            'Go BIG this year with a new, affordable manufactured home.'
          }
          content={`We're changing the way homes are built, making them affordable to buy and cost less to live in. Every ClaytonBuilt® home uses quality products from the world's most trusted brands and comes equipped with EnergySmart™ features so you can save money for years to come. For a limited time, you can save even more on select homes during our Go Big & Go Home Sales Event.`}
          backgroundColor={'#f3f5f7'}
          mobileBackgroundColor={'#f3f5f7'}
          largePadding={true}
          customHeadlineWidth={'600px'}
          isPromo={true}
        />
        {homesOnSale && homesOnSale.length > 0 && (
          <>
            <SaleHomeSlider
              sliderId="promoSlider"
              largeHeadline="Featured Sale Homes"
              dealer={dealer}
              saleHomes={homesOnSale}
              buttonText={'View All Sale Homes'}
              buttonURL={'/sale-homes'}
              gaClass={'ga-seasonal-campaign-view-sale-homes'}
              retailEventLabel={'campaign_sale_homes_view_all'}
            />
          </>
        )}
        <Video
          gaClass={'ga-seasonal-campaign-video'}
          retailEventLabel={'campaign_video'}
          image={{ source: VideoBackgroundImage, alt: 'Go Big & Go Home Sale' }}
          videoID={isClayton() ? 'bxp36063ho' : 'rhtvl5ubgc'}
          wistiaOptions={'silentAutoPlay=false autoPlay=true'}
          noSaleHomes={true}
        />
        <HouseSmartPromoGrid hideBottomRow={true} hideDisclaimer={true} />
        <ExploreWaysToSaveBanner
          headline={'Explore More Ways to Save'}
          backgroundColor={Colors.accent.goBigGoHomeDarkBlue}
          backgroundImages={{
            desktop: ExploreMoreWaysSaveBackground,
            tablet: ExploreMoreWaysSaveTabletBackground,
            mobile: ExploreMoreWaysSaveMobileBackground,
          }}
        />
        {featuredEvent && (
          <FeaturedEvent
            buttonText={'Learn More'}
            smallHeadline={'Upcoming Events'}
            featuredEvent={featuredEvent}
            isSpecialOffers={true}
          />
        )}
        <PromoContent
          isSeasonalCampaign={true}
          formatDealerName={formatDealerName}
          dealer={dealer}
          homes={homes}
        />
        <div id="PromoDisclaimer" ref={disclaimerRef}>
          <PromoDisclaimer
            disclaimer={
              '*Starting sales price is for select new homes only. Sales price does not include delivery and installation or other costs such as taxes, title fees, insurance premiums, filing or recording fees, land or improvements to the land, optional home features, optional delivery or installation services, wheels and axles, community or homeowner association fees, or any other items not shown on your Sales Agreement, Retailer Closing Agreement, and related documents (your SA/RCA). If you purchase a home, your SA/RCA will show the details of your purchase. The $150,000 sales price will vary by retailer and state and may not be available for all models. See local retailer for list of eligible homes. Available with a purchase agreement signed January 1, 2025 through April 30, 2025. Available only at participating Clayton Family of Brands retailers.'
            }
          />
        </div>
        <XLCTA
          customCallText="Find Your New Home"
          dealer={dealer}
          homes={homes}
          hasDarkBackground={true}
          buttons={[
            {
              buttonText: 'Get More Information',
              buttonURL: '/contact-us',
              gaClass: 'ga-seasonal-campaign-get-more-information',
              textStyle: ' dark-text',
              openForm: 'requestInfo',
              retailEventLabel: 'footer_dual_cta_request',
            },
            {
              buttonText: 'Schedule a Visit',
              buttonURL: '/contact-us',
              gaClass: 'ga-seasonal-campaign-schedule-a-visit',
              openForm: 'scheduleVisit',
              retailEventLabel: 'footer_dual_cta_schedule',
            },
          ]}
        />
      </WinterPromoStyles>
    </App>
  );
};

WinterPromo.getInitialProps = async (ctx) => {
  let featuredEvent;
  const { dealer, homes, homesOnSale } = await parseInitialPropsContext(
    ctx,
    dealerService,
    homesService
  );

  try {
    const result = await getNewsAndEvents(dealer?.dealerId);
    featuredEvent = result.filter((event) => event.isFeatured)[0];
  } catch (e) {
    console.error(e);
  }

  return { dealer, homes, homesOnSale, featuredEvent };
};

export default WinterPromo;
