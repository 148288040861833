import styled from 'styled-components';
import Colors from '../../colors';

const StyledDisclaimer = styled.div`
  p {
    font: 400 12px/14px 'SourceSans-Pro Regular', sans-serif;
    color: ${Colors.accent.grey1.standard};
    width: 87%;
    margin: 67px auto;
    max-width: 1224px;

    @media only screen and (min-width: 768px) {
      width: 67%;
    }

    @media screen and (min-width: 1025px) {
      width: 85%;
      margin: 45px auto;
    }
  }
`;

export default StyledDisclaimer;
