import React from 'react';
import Form from './Form';
import ThankYouMessage from './ThankYouMessage';
import WinterPromoBannerDesktop from '../../images/promo-images/winter-promo/go-big-go-home-banner.webp';
import WinterPromoBannerDesktopTablet from '../../images/promo-images/winter-promo/go-big-go-home-banner-tablet.webp';
import WinterPromoBannerDesktopMobile from '../../images/promo-images/winter-promo/go-big-go-home-banner-mobile.webp';
import GoBigGoHomeLogo from '../../images/promo-images/winter-promo/gbgh-logo.svg';
import GoBigGoHomeTabletLogo from '../../images/promo-images/winter-promo/gbgh-logo-tablet.svg';
import { StyledBanner } from './Banner.Style';
import useWindowSize from '../../hooks/useWindowSize';
import { useLocation } from 'react-router-dom';

const Banner = ({
  showThankYouCallBack,
  isClayton,
  dealer,
  formHeadline,
  formSubheadline,
  formTopDisclaimer,
  formDisclaimer,
  formText,
}) => {
  const [width, height] = useWindowSize();

  const isTablet = width <= 1023 && width >= 767;

  const { pathname } = useLocation();

  const isConfirmationPage = pathname.includes('/thank-you');

  return (
    <StyledBanner>
      <div className="banner">
        <img
          src={isTablet ? GoBigGoHomeTabletLogo : GoBigGoHomeLogo}
          alt="Go Big and Go Home logo"
          className="go-big-go-home-logo"
        />
        <img
          src={WinterPromoBannerDesktop}
          alt="Go Big and Go Home Sales Event banner"
          className="have-it-made-banner banner-img"
        />
        <img
          src={WinterPromoBannerDesktopTablet}
          alt="Go Big and Go Home Sales Event banner"
          className="have-it-made-banner-tablet banner-img"
        />
        <img
          src={WinterPromoBannerDesktopMobile}
          alt="Go Big and Go Home Sales Event banner"
          className="have-it-made-banner-mobile banner-img"
        />
      </div>
      {isConfirmationPage ? (
        <ThankYouMessage
          buttonText={'View Homes'}
          tyMsg={
            <>
              Thank you for reaching out. A home center
              <br /> consultant will be in touch shortly.
            </>
          }
        />
      ) : (
        <Form
          formHeadline={formHeadline}
          formSubHeadline={formSubheadline}
          formTopDisclaimer={formTopDisclaimer}
          formDisclaimer={formDisclaimer}
          formText={formText}
          showThankYouCallBack={showThankYouCallBack}
          isClayton={isClayton}
          dealer={dealer}
        />
      )}
    </StyledBanner>
  );
};

export default Banner;
