import PropTypes from 'prop-types';
import React from 'react';
import useWindowSize from '../../../hooks/useWindowSize';
import ExploreWaysToSaveBannerStyles from './ExploreWaysToSaveBanner.styled';

export const ExploreWaysToSaveBanner = ({
  headline,
  backgroundColor,
  backgroundImages,
}) => {
  const [width, height] = useWindowSize();
  const isTablet = width <= 1099;
  const isMobile = width <= 767;

  const backgroundImage = () => {
    if (isMobile) {
      return backgroundImages.mobile;
    }
    if (isTablet) {
      return backgroundImages.tablet;
    }

    return backgroundImages.desktop;
  };

  return (
    <ExploreWaysToSaveBannerStyles backgroundColor={backgroundColor}>
      <img src={backgroundImage()} alt="Explore More Ways to Save" />
      <h2>{headline}</h2>
    </ExploreWaysToSaveBannerStyles>
  );
};

ExploreWaysToSaveBanner.props = {
  headline: PropTypes.string,
  backgroundColor: PropTypes.string,
};

ExploreWaysToSaveBanner.defaultProps = {
  backgroundColor: 'blue',
};

export default ExploreWaysToSaveBanner;
