import styled from 'styled-components';
import Colors from '../../colors';

export const StyledTyMsg = styled.div`
  background: linear-gradient(
    180deg,
    ${Colors.accent.goBigGoHomeDarkBlue.standard} 0%,
    ${Colors.accent.goBigGoHomeLightBlue.standard} 100%
  );

  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 120px 0;

  @media screen and (min-width: 768px) {
    padding: 194px 0;
  }

  @media screen and (min-width: 1025px) {
    max-width: none;
    width: 50%;
    margin: unset;
  }

  .message-container {
    .house-container {
      margin: 0 auto 23px;
      text-align: center;
    }

    p {
      font: 400 18px/29px 'SourceSans-Pro Regular', sans-serif;
      color: ${Colors.primary.white.standard};
      text-align: center;
      margin: 30px auto 90px;
      width: 87%;

      @media only screen and (min-width: 768px) {
        width: auto;
      }

      br {
        display: none;

        @media only screen and (min-width: 768px) {
          display: block;
        }
      }
    }

    .button {
      font: 600 14px/100% 'Source Sans Pro', sans-serif;
      color: ${Colors.primary.white.standard};
      text-align: center;
      width: 87%;
      border-radius: 2px;
      background: ${Colors.accent.goBigGoHomeRed.standard};
      display: block;
      padding: 18px 0;
      margin: 0 auto;
      text-decoration: none;
      text-transform: uppercase;
      max-width: 327px;

      @media only screen and (min-width: 768px) {
        max-width: none;
        width: 392px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
`;
