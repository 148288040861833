import styled from 'styled-components';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';
import Colors from '../../../colors';

const LargeCTAStyles = styled.div`
  .cta-container {
    position: relative;
    max-width: 1250px;
    margin: 0 auto;
    width: 90%;
    @media only screen and (max-width: 768px) {
      max-width: ${(props) =>
        props.absoluteLogoPositionRight ? '90%' : '512px'};
    }
  }
  picture {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    img {
      object-fit: cover;
      height: auto;
      width: 100% !important;
      object-position: center;

      @media screen and (min-width: 1100px) {
        object-position: left;
      }
    }
  }
  .logo {
    margin-bottom: 20px;
    max-width: 100%;
  }
  .purple-background {
    background: ${Colors.secondary.summerNight.standard};
    .cta-button {
      color: ${Colors.secondary.summerNight.standard};
    }
  }
  .navy-background {
    background: #021f66;
    .cta-button {
      color: #021f66;
    }
  }
  .red-background {
    background: #e41d3a;
    .cta-button {
      color: #e41d3a;
    }
  }
  .teal-background {
    background: #4495a7;
    .cta-button {
      color: #06292f !important;
      background: #fdba19 !important;
    }
  }
  .blue-background {
    background: ${Colors.primary.claytonBlue.standard};
    .cta-button {
      color: ${Colors.primary.claytonBlue.standard};
    }
  }
  .large-cta {
    position: relative;
    padding: 48px 44px;
    border: 0px solid;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: ${(props) =>
      props.backgroundPosition ? props.backgroundPosition : 'center right'};
    background-size: cover;
    overflow: hidden;
    width: 100%;
    display: flex;
    @media only screen and (min-width: 1100px) {
      min-height: 250px;
    }
    @media only screen and (max-width: 1099px) {
      padding: 40px 25px;
    }
    .content {
      position: relative;
      max-width: 385px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: 2;
      @media only screen and (max-width: 1099px) {
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 auto;
      }
    }
    .small-text {
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      font-size: 12px;
      line-height: 1;
      margin-bottom: 12px;
      letter-spacing: 0.5px;
      font-family: 'source-sans-pro';
    }
    .large-text {
      color: white;
      line-height: 38px;
      margin-bottom: 28px;
      text-align: left;
      @media only screen and (max-width: 1100px) {
        line-height: 1.1;
        margin-bottom: 20px;
      }
    }
    .cta-button {
      background: ${Colors.primary.white.standard};
      border-radius: 2px;
      padding: 17px 17px;
      font: 500 12px/100% 'source-sans-pro', sans-serif;
      margin-top: 9px;
      letter-spacing: 1px;
      text-decoration: none;
      transition: 0.2s;
      text-transform: uppercase;
      font-weight: 700;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  .absolute-logo-wrap {
    display: flex;
    justify-content: ${(props) =>
      props.absoluteLogoPositionRight ? 'flex-end' : 'left'};
    /* padding-right: ${(props) =>
      props.absoluteLogoPositionRight ? '100px' : '0px'}; */
    flex: 1;
    z-index: 9;
    position: absolute;
    top: 0;
    right: 0;
    @media only screen and (max-width: 1099px) {
      display: none;
    }
  }
  .have-it-made-background {
    @media only screen and (max-width: 1099px) {
      background: ${Colors.accent.bedtime.standard};
    }
    .small-text {
      color: ${Colors.primary.white.standard} !important;
      @media only screen and (min-width: 1100px) {
        display: none;
      }
    }
    .large-text {
      /* @media only screen and (min-width: 1100px) { */
      color: ${Colors.primary.white.standard} !important;
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 40px;
      /* } */
    }
    .cta-button {
      color: ${Colors.accent.bedtime.standard} !important;
      background: ${Colors.accent.hoseWater.standard} !important;
    }
  }

  .blue-background {
    .absolute-logo-wrap {
      display: flex;
      -webkit-box-pack: end;
      justify-content: flex-end;
      flex: 1 1 0%;
      position: relative;
      @media only screen and (max-width: 1099px) {
        display: none;
      }
      img {
        max-width: 494px;
        height: auto;
        position: absolute;
        right: 0;
        max-height: 100%;
      }
    }
  }

  .inventory-clearance-event-background {
    padding: 34px 18px 34px 48px;
    @media only screen and (max-width: 1099px) {
      background: ${Colors.accent.darkClearanceRed.standard};
      padding: 35px 48px;
    }
    .small-text {
      color: ${Colors.primary.white.standard} !important;
    }
    .content {
      max-width: 500px !important;
    }
    .large-text {
      color: ${Colors.primary.white.standard} !important;
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 41px;
      letter-spacing: -0.3px;
      margin-bottom: 12px;
      @media only screen and (max-width: 1100px) {
        font-size: 26px;
        margin-bottom: 19px;
      }
    }
    .cta-button {
      color: ${Colors.accent.darkClearanceRed.standard} !important;
      background: ${Colors.primary.white.standard} !important;
      font-size: 14px;
      margin-top: 18px;
    }
    .absolute-logo-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex-grow: 1;
      z-index: 5;
      margin-top: 27px;
      @media only screen and (max-width: 1099px) {
        display: none;
      }
      img {
        height: auto;
        position: absolute;
        right: 18px;
        top: 21px;
      }
    }
  }
  .logo-text {
    color: ${Colors.primary.white.standard};
    font-size: 28px;
    font-weight: 400;
    text-align: right;
    line-height: 1;
    letter-spacing: -0.35px;
    padding-right: 5px;
  }
  .bold {
    font-weight: 900;
  }
  .disclaimer {
    color: ${Colors.primary.white.standard};
    font-size: 12px;
    font-weight: 900;
    @media only screen and (max-width: 1300px) {
      max-width: 400px;
    }
  }

  .large-cta.go-big-go-home-background {
    display: flex;
    padding: 32px 0;
    text-align: center;
    height: 275px;

    @media only screen and (min-width: 1100px) {
      justify-content: flex-start;
      align-items: center;
      margin: 0 auto;
      padding: 32px 48px;
      height: 250px;
      background: linear-gradient(
        180deg,
        ${Colors.accent.goBigGoHomeDarkBlue.standard} 0%,
        ${Colors.accent.goBigGoHomeLightBlue.standard} 100%
      );
    }
    .content {
      height: 186px;
      max-width: 310px;
      justify-content: space-between;
      p.h2.large-text {
        font-size: 40px;
        font-weight: 900;
        line-height: 40px;
        letter-spacing: -0.35px;
        color: ${Colors.primary.white.standard};
        margin-bottom: 0;
        padding-bottom: 28px;
        text-align: center;

        @media screen and (min-width: 1025px) {
          text-align: left;
        }
      }
      .cta-button {
        color: ${Colors.primary.white.standard};
        background-color: ${Colors.accent.goBigGoHomeRed.standard};
        margin-top: 4px;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
`;

export default LargeCTAStyles;
