import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

const TextContentStyles = styled.section`
  position: relative;
  padding: ${(props) =>
    props.largePadding == true
      ? '130px 0px'
      : props.overlapBelowVideo == true
      ? '170px 0px 130px'
      : props.aboveVideoContent == true
      ? '130px 0px 72px'
      : '50px 0px'};
  margin-top: ${(props) => (props.overlapBelowVideo == true ? '-90px' : null)};
  background: ${(props) => props.backgroundColor || 'white'};
  @media only screen and (max-width: 910px) {
    padding: ${(props) =>
      props.largePadding == true
        ? '112px 0px'
        : props.overlapBelowVideo == true
        ? '142px 0px 112px'
        : props.aboveVideoContent == true
        ? '112px 0px 52px'
        : '20px 0px'};
    background: ${(props) => props.mobileBackgroundColor || 'white'};
  }
  .superscript {
    vertical-align: super;
    font-size: 12px;
    line-height: 0;
    font-family: 'source-sans-pro', sans-serif;
    color: ${Colors.accent.grey1.standard};
    font-weight: 700;
  }
  .container {
    position: relative;
    max-width: 1304px;
    margin: 0 auto;
    padding: 0px 40px;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 910px) {
      padding: 0px 20px;
      max-width: 552px;
      margin: 0 auto;
    }

    &.promo {
      padding: 50px 20px;
      max-width: 640px;
    }
    .inner {
      max-width: ${(props) => (props.isPromo == true ? '600px' : '648px')};
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      .small-headline {
        display: block;
        color: #6a6f72;
        font-family: 'source-sans-pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.5px;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 7px;
      }
      .large-headline {
        margin: 0px auto 32px;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: -0.35px;
        max-width: ${(props) =>
          props.customHeadlineWidth ? props.customHeadlineWidth : '464px'};
      }
      .content {
        color: #4e5255;
        p {
          color: #4e5255;
        }
      }
      .link-content {
        font-family: 'source-sans-pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
        color: #4e5255;

        a {
          text-decoration: underline;
          font-family: 'source-sans-pro';
          font-weight: 400;
          color: #4e5255;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .line {
        display: flex;
        margin-top: 40px;
        margin-bottom: 30px;
        width: 105px;
        height: 1px;
        background: ${Colors.accent.grey4.standard};
      }
      .link-content {
        font-family: 'source-sans-pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: ${Colors.primary.black.standard};

        .link-text {
          font-family: 'source-sans-pro';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #000;
        }

        .bold {
          font-weight: 700;
          font-family: 'source-sans-pro';
          color: ${Colors.primary.black.standard};
          font-size: 12px;
        }

        a {
          text-decoration: underline;
          font-family: 'source-sans-pro';
          font-weight: 400;
          color: #000;

          &:hover {
            cursor: pointer;
          }
        }
      }
      .small-disclaimer {
        font-family: 'source-sans-pro';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        color: ${Colors.primary.black.standard};
        margin-top: 20px;

        a {
          cursor: pointer;
          text-decoration: underline;
          color: ${Colors.primary.black.standard};
          font-weight: normal;
          font-family: 'source-sans-pro';
        }

        span {
          cursor: pointer;
          text-decoration: underline;
          color: ${Colors.primary.black.standard};
          font-weight: normal;
          font-family: 'source-sans-pro';
        }
      }
    }
  }
`;

export default TextContentStyles;
