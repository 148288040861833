import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import App from '../../App';
import Colors from '../../colors';
import VideoBackgroundImage from '../../images/promo-images/fall-promo/red-tag-sale-video-background.webp';
import ExploreMoreWaysSaveBackground from '../../images/explore-more-ways-save-background.webp';
import ExploreMoreWaysSaveTabletBackground from '../../images/explore-more-ways-save-background-tablet.webp';
import ExploreMoreWaysSaveMobileBackground from '../../images/explore-more-ways-save-background-mobile.webp';
import RedTagHouseConfirmation from '../../images/svg/promo-logos/RedTagHouseConfirmation';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { getNewsAndEvents } from '../../Api.js';
import { parseInitialPropsContext } from '../../utils';
import SaleHomeSlider from '../Shared/SaleHomeSlider/SaleHomeSlider';
import HouseSmartPromoGrid from '../Shared/HouseSmartPromoGrid/HouseSmartPromoGrid';
import FeaturedEvent from '../Shared/FeaturedEvent/FeaturedEvent';
import TextContent from '../Shared/TextContent/TextContent';
import Video from '../Shared/Video/Video';
import XLCTA from '../Shared/XLCTA/XLCTA';
import { StyledFallPromoThankYou } from './FallPromoThankYou.Style';
import PromoDisclaimer from './PromoDisclaimer';
import PromoContent from '../Promo/PromoContent';
import ExploreWaysToSaveBanner from '../Shared/ExploreWaysToSaveBanner/ExploreWaysToSaveBanner.js';
import PromoBannerClayton from '../../images/promo-images/fall-promo/red-tag-sale-confirmation-background.webp';
import PromoBannerClaytonTablet from '../../images/promo-images/fall-promo/red-tag-sale-confirmation-background-tablet.webp';
import PromoBannerClaytonMobile from '../../images/promo-images/fall-promo/red-tag-sale-confirmation-background-mobile.webp';
import useWindowSize from '../../hooks/useWindowSize.js';

const promoIsInactive = false;

const FallPromoThankYou = ({ dealer, homesOnSale, homes, featuredEvent }) => {
  const [width, height] = useWindowSize();
  const isTablet = width <= 1023;
  const isMobile = width <= 767;
  const disclaimerRef = useRef();

  const handleScroll = () => {
    window.scrollTo({
      top: disclaimerRef.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const isClayton = () => {
    if (dealer?.brandAcronym.toLowerCase() === 'cla') {
      return true;
    }
    return false;
  };

  if (dealer?.websiteTemplateDescription === 'BuilderModel') {
    return <Redirect to="/" />;
  }

  if (promoIsInactive) {
    return <Redirect to="/special-offers" />;
  }

  const backgroundImage = () => {
    if (isMobile) {
      return PromoBannerClaytonMobile;
    }
    if (isTablet) {
      return PromoBannerClaytonTablet;
    }

    return PromoBannerClayton;
  };

  return (
    <App dealer={dealer} homes={homes}>
      <StyledFallPromoThankYou>
        <Helmet>
          <title>
            Red Tag Sales Event - Affordable Modern Manufactured, Mobile Homes |{' '}
            {dealer ? formatDealerName(dealer?.dealerName) : ''}
          </title>
          <meta
            name="description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
          <meta
            name="og:description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
        </Helmet>
        <div className="ty-message-banner">
          <div className="banner">
            <img src={backgroundImage()} className="banner-img" />
          </div>
          <div className="confirmation-message">
            <div className="message-container">
              <h3>Thank You!</h3>
              <div className="house-container">
                <RedTagHouseConfirmation />
              </div>
              <p>
                Thank you for reaching out. A home center
                <br /> consultant will be in touch shortly.
              </p>
              <Link
                to={{
                  pathname: '/sale-homes',
                  state: { dealer, homes },
                }}
                id="viewHomes"
                className="button"
              >
                View Homes
              </Link>
            </div>
          </div>
        </div>
        <TextContent
          smallHeadline={`red tag sale`}
          largeHeadline={
            'It’s the best time of year to find a beautiful, affordable home.'
          }
          content={
            <>
              For a limited time, we're reducing prices on our modern
              manufactured homes and making the dream of owning a new home even
              more within reach. Save thousands on select in-stock homes during
              our annual Red Tag Sales Event!
            </>
          }
          smallDisclaimer={
            '*Sale homes will vary by retailer and state. See local retailer for list of eligible homes.'
          }
          backgroundColor={Colors.secondary.grey6.standard}
          mobileBackgroundColor={Colors.secondary.grey6.standard}
          aboveVideoContent={false}
          isPromo={true}
        />
        {homesOnSale && homesOnSale.length > 0 && (
          <SaleHomeSlider
            sliderId="promoSlider"
            largeHeadline={
              !promoIsInactive ? 'Featured Sale Homes' : 'Explore Homes'
            }
            saleHomes={homesOnSale}
            buttonText={'View All Sale Homes'}
            buttonURL={'/sale-homes'}
            gaClass={'ga-seasonal-campaign-view-sale-homes'}
            retailEventLabel={'campaign_sale_homes_view_all'}
            dealer={dealer}
          />
        )}
        <Video
          gaClass={'ga-seasonal-campaign-video'}
          retailEventLabel={'campaign_video'}
          image={{ source: VideoBackgroundImage }}
          videoID={isClayton() ? 'q3y85hn5de' : 'tssi6e1jtv'}
          wistiaOptions={'silentAutoPlay=false autoPlay=true'}
          noSaleHomes={true}
        />
        <HouseSmartPromoGrid hideBottomRow={true} hideDisclaimer={true} />
        <ExploreWaysToSaveBanner
          headline={'Explore More Ways to Save'}
          backgroundImages={{
            desktop: ExploreMoreWaysSaveBackground,
            tablet: ExploreMoreWaysSaveTabletBackground,
            mobile: ExploreMoreWaysSaveMobileBackground,
          }}
        />
        {featuredEvent && (
          <FeaturedEvent
            buttonText={'Learn More'}
            smallHeadline={'Upcoming Events'}
            featuredEvent={featuredEvent}
            isSpecialOffers={true}
          />
        )}
        <PromoContent
          isSeasonalCampaign={true}
          formatDealerName={formatDealerName}
          dealer={dealer}
          homes={homes}
        />
        <div id="PromoDisclaimer" ref={disclaimerRef}>
          <PromoDisclaimer
            mainDisclaimer={`* CONDITIONS FOR THE ENERGY SMART ZERO™ EXTENDED SALES EVENT
            OFFER (the “Offer”): Offer only available at participating
            Clayton Family of Brands retailers with purchase of a new
            EnergySmart Zero™ home at the posted retail sales price with a
            Sales Agreement signed 1/1/2024 through 3/31/2024. Eligible
            buyers will receive a $2,000 VISA® prepaid debit card for the
            home purchase. Offer will be applied first to the amount of the
            negotiated or lender required reduction of the home sales price
            if applicable. The $2,000 may not be applied towards the buyer's
            down payment if the buyer obtains financing for the home
            purchase. Offer will be in the form of a VISA® prepaid debit
            card issued in the name of the primary buyer by Pathward®
            pursuant to a license from VISA U.S.A. and is usually delivered
            within eight weeks after the Seller has been paid in full for
            all goods and services purchased by the buyer. VISA debit card
            is subject to all terms and conditions sets forth on the card
            (including the expiration date) and in the issuer’s Cardholder
            Agreement. Offer not available with USDA/FHA/VA financing and
            cannot be transferred or combined with other offers. Void where
            prohibited by law.`}
            disclaimerLinkText={'** ZERH Logo Use Guidelines (energy.gov)'}
            disclaimerLink={
              'https://www.energy.gov/sites/default/files/2022-11/ZERH%20Name%20and%20Logo%20Use%20Guidelines_0.pdf'
            }
          />
        </div>
        {dealer && (
          <XLCTA
            phoneNumber={dealer.phoneNumber}
            customCallText={'Find Your Perfect Home'}
            gaPhoneClass={'ga-seasonal-campaign-phone-number'}
            buttons={[
              {
                buttonText: 'Get More Information',
                buttonURL: '/contact-us',
                gaClass: 'ga-seasonal-campaign-get-more-information',
              },
              {
                buttonText: 'Schedule a Visit',
                buttonURL: '/contact-us',
                gaClass: 'ga-seasonal-campaign-schedule-a-visit',
              },
            ]}
          />
        )}
      </StyledFallPromoThankYou>
    </App>
  );
};

FallPromoThankYou.getInitialProps = async (ctx) => {
  let featuredEvent;
  const { dealer, homes, homesOnSale } = await parseInitialPropsContext(
    ctx,
    dealerService,
    homesService
  );

  try {
    const result = await getNewsAndEvents(dealer?.dealerId);
    featuredEvent = result.filter((event) => event.isFeatured)[0];
  } catch (e) {
    console.error(e);
  }

  return { dealer, homes, homesOnSale, featuredEvent };
};

export default FallPromoThankYou;
