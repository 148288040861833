import React from 'react';
import { StyledTyMsg } from './ThankYouMessage.Style';
import GoBigGoHomeHouseConfirmation from '../../images/svg/promo-logos/GoBigGoHomeHouseConfirmation';
import { Link } from 'react-router-dom';

const ThankYouMessage = ({ dealer, homes, tyMsg, buttonText }) => {
  return (
    <StyledTyMsg>
      <div className="message-container">
        <div className="house-container">
          <GoBigGoHomeHouseConfirmation />
        </div>
        <p>{tyMsg}</p>
        <Link
          to={{
            pathname: '/homes',
            state: { dealer, homes },
          }}
          id="viewHomes"
          className="button"
        >
          {buttonText}
        </Link>
      </div>
    </StyledTyMsg>
  );
};

export default ThankYouMessage;
