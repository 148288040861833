import Button from 'pubweb-smokey/dist/components/Buttons/Button';
import {
  formatDealerName,
  formatNumberWithCommas,
  formatPhoneNumberFromNumber,
} from 'pubweb-smokey/dist/utils/formatters';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Link, useHistory, useLocation } from 'react-router-dom';
import App from '../../App';
import Colors from '../../colors';
import { modelNameTransformer } from '../../components/Shared/SmokeyTransitional/utils/modelNameTransformer';
import { DXAccountContext } from '../../contexts/DXAccountContext';
import useSticky from '../../hooks/useSticky';
import useWindowSize from '../../hooks/useWindowSize';
import backBtnArrow from '../../images/portal/backBtnArrow.svg';
import cubeIcon from '../../images/svg/cube-open.svg';
import houseSmartLogo from '../../images/svg/housesmart-cta-image.svg';
import bath from '../../images/svg/iconography-16x16/bath.svg';
import bed from '../../images/svg/iconography-16x16/bed.svg';
import homeIcon from '../../images/svg/iconography-16x16/home.svg';
import mountains from '../../images/svg/iconography-16x16/mountains.svg';
import phoneIcon from '../../images/svg/iconography-16x16/phone.svg';
import ArrowDown from '../../images/svg/icons/ArrowDown';
import Clock from '../../images/svg/icons/Clock';
import EnvelopeClosed from '../../images/svg/icons/EnvelopeClosed';
import Heart from '../../images/svg/icons/Heart';
import HeartOutline from '../../images/svg/icons/HeartOutline';
import Loading from '../../images/svg/icons/Loading';
import nextBtn from '../../images/svg/next-btn.svg';
import ZoomIn from '../../images/svg/zoom-in.svg';
import ZoomOut from '../../images/svg/zoom-out.svg';
import LongFormCtaBackground from '../../images/webp/long-form-cta-background.webp';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { fireSegmentEvent, parseInitialPropsContext } from '../../utils';
import { ENVIRONMENT } from '../../utils/config';
import HomeTileModel from '../HomeList/HomeTile/HomeTileModel';
import LargeCTA from '../Shared/LargeCTA/LargeCTA';
import LongFormCTA from '../Shared/LongFormCTA/LongFormCTA';
import RecentlyViewedHomes from '../Shared/RecentlyViewedHomes/RecentlyViewedHomes';
import RequestInfoModalForm from '../Shared/RequestInfoModalForm/RequestInfoModalForm';
import ScheduleVisitModalForm from '../Shared/ScheduleVistModalForm/ScheduleVisitModalForm';
import Modal from '../Shared/SmokeyTransitional/components/Modal/Modal';
import { formatDescription } from '../Shared/SmokeyTransitional/utils/formatters';
import XLCTA from '../Shared/XLCTA/XLCTA';
import FeatureListItem from './FeatureListItem.js';
import GoogleMap from './GoogleMap';
import HomeDetailsStyles from './HomeDetails.styled';
import HomeDetailsStructuredData from './HomeDetailsStructuredData';
import MissingHome from './MissingHome';
import NavBar from './Navbar';
import TooltipIcon from './TooltipIcon';
import { Lightbox } from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/dist/styles.css';
import Zoom from 'yet-another-react-lightbox/dist/plugins/zoom';

const buildHomeUrl = (hostname, home) => {
  if (hostname && home) {
    return (
      'https://' +
      hostname +
      '/homes/' +
      (home.isAvailableFloorPlan
        ? `model/${home.modelNumber}`
        : home.serialNumber || home.inventoryId || home.stockNumber)
    );
  }
};

const HomeDetails = ({
  dealer,
  homes,
  home,
  hostname,
  isLoading,
  showFloorPlans,
  match,
}) => {
  const accountContext = useContext(DXAccountContext);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [inLocal, setInLocal] = useState(false);
  const [flpOpen, setFlpOpen] = useState(false);
  const [slideOpen, setSlideOpen] = useState(false);
  const [slideIndex, setSlideIndex] = React.useState(0);
  const [maxZoomPixelRatio, setMaxZoomPixelRatio] = useState(2);
  const [zoomInMultiplier, setZoomInMultiplier] = useState(5);
  const [scrollToZoom, setScrollToZoom] = useState(true);
  const [width, height] = useWindowSize();
  const { isSticky, element } = useSticky();
  const location = useLocation();
  const isLandPage = location.pathname == '/land';
  const isMobile = width <= 1095;
  const year = new Date().getFullYear();
  const redirectPath = location?.pathname;
  const history = useHistory();
  const [recentHomes, setRecentHomes] = useState([]);

  const homeDetailsSegmentEvent = () => {
    return fireSegmentEvent('CTA Clicked', {
      isNav: false,
      type: 'button',
      text: 'TAKE 3D TOUR',
      clickId: '',
      eventContext: 'home_details_3d_tour',
    });
  };

  const Controls = () => {
    return (
      <div className="tools">
        <button
          className="zoom-in"
          onClick={() => {
            setFlpOpen(true);
          }}
        >
          <img src={ZoomIn} alt="zoom in" />
        </button>
      </div>
    );
  };

  useEffect(() => {
    const items = JSON.parse(localStorage?.getItem('recentlyViewedHomes'));

    if (home && (home?.serialNumber || home?.modelNumber)) {
      if (items) {
        setRecentHomes(() => [
          {
            serialNumber: home?.serialNumber,
            modelNumber: home?.modelNumber,
            lotNumber: dealer?.dealerId,
          },
          ...items,
        ]);
      } else {
        setRecentHomes((rvh) => [
          {
            serialNumber: home?.serialNumber,
            modelNumber: home?.modelNumber,
            lotNumber: dealer?.dealerId,
          },
          ...rvh,
        ]);
      }
    }
  }, [home]);

  useEffect(() => {
    if (recentHomes && recentHomes.length) {
      let uniqueArray = recentHomes
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.lotNumber === value.lotNumber &&
                t.serialNumber === value.serialNumber &&
                t.modelNumber === value.modelNumber
            )
        )
        .slice(0, 100);
      localStorage.setItem('recentlyViewedHomes', JSON.stringify(uniqueArray));
    }
  }, [recentHomes]);

  useEffect(() => {
    if (
      ENVIRONMENT.indexOf('dev') === -1 ||
      ENVIRONMENT.indexOf('itg') === -1 ||
      ENVIRONMENT.indexOf('qua') === -1 ||
      ENVIRONMENT.indexOf('prod') === -1
    ) {
      setInLocal(true);
    }
  }, [inLocal]);

  const favoriteCheck = (home) => {
    let favCheck = accountContext?.state?.favorites.some((element) => {
      if (
        element?.modelNumber === home?.modelNumber &&
        element?.serialNumber === home?.serialNumber
      ) {
        return true;
      } else {
        return false;
      }
    });

    return favCheck;
  };

  const LoadingIcon = <Loading width="20px" color="#25292D" />;

  const allFeatures = () => {
    if (
      !home ||
      (home.modelFeatures.length === 0 && home.inventoryFeatures.length === 0)
    ) {
      return null;
    }

    if (home.modelFeatures.length === 0) {
      return home.inventoryFeatures;
    }

    if (home.inventoryFeatures.length === 0) {
      return home.modelFeatures;
    }

    return [...home.modelFeatures, ...home.inventoryFeatures];
  };

  const homeObject = home
    ? HomeTileModel.initWithRetailHomeObject(home, dealer, homes)
    : null;

  const generatePriceOverride = () => {
    // create pricing area markup based on price
    if (homeObject.price.includes('Was')) {
      const parts = homeObject.price.split(' ');
      const oldPrice = parts[1].slice(0, parts[1].length - 1);
      const newPrice = parts[3];
      return (
        <div>
          <div className="price-top large-price">
            {newPrice}{' '}
            <span className="small-price">
              WAS <del>{oldPrice}</del>
            </span>
            <TooltipIcon />
          </div>
        </div>
      );
    }

    if (homeObject.price.includes('Before')) {
      const parts = homeObject.price.split(' ');
      const beforeOptionsPrice = parts[0];
      return (
        <div>
          <div className="price-top">
            {beforeOptionsPrice}* BEFORE OPTIONS
            <TooltipIcon />
          </div>
        </div>
      );
    }

    if (homeObject.price.includes('Contact Us')) {
      return (
        <Link
          to={{ pathname: '/contact-us', state: { dealer, homes } }}
          className="price-contact-us"
        >
          <img
            className="phone-icon"
            src={phoneIcon}
            alt="phone icon"
            loading="lazy"
          />
          {homeObject.price}
        </Link>
      );
    }

    return (
      <div>
        <div className="price-top large-price">
          {homeObject.price}
          <TooltipIcon />
        </div>
      </div>
    );
  };

  const arrowStyles = {};

  const fullAddress = () => {
    if (
      (homeObject &&
        (homeObject.isLand || homeObject.isOnLand) &&
        homeObject.address1.toLowerCase().includes(' tbd ')) ||
      homeObject.address1.toLowerCase().includes('tbd ') ||
      homeObject.address1.toLowerCase().includes(' tbd') ||
      homeObject.address1.toLowerCase() === 'tbd'
    ) {
      return `${homeObject.city}, ${homeObject.state} ${homeObject.postalCode}`;
    }

    if (homeObject && (homeObject.isLand || homeObject.isOnLand)) {
      return `${homeObject.address1}, ${homeObject.city}, ${homeObject.state} ${homeObject.postalCode}`;
    }

    return null;
  };

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [width, height]);

  const findPathName = (pathname) => {
    if (pathname == '/portal/budget') {
      return 'Budget';
    } else if (pathname == '/portal/planner') {
      return 'MyHome Planner';
    } else if (pathname == '/portal/home-buying-journey') {
      return 'Home Buying Journey';
    } else if (pathname == '/portal/my-home-aspirations') {
      return 'My Home Aspirations';
    } else if (pathname == '/portal/favorites') {
      return 'My Favorites';
    } else if (pathname == '/portal/homes-for-you') {
      return 'Homes For You';
    } else if (pathname == '/portal/land') {
      return 'Land';
    } else {
      return 'Dashboard';
    }
  };

  const buildHomePath = (home) => {
    if (home && !inLocal) {
      return (
        'homes/' +
        (home.isAvailableFloorPlan
          ? `model/${home.modelNumber}`
          : home.serialNumber || home.inventoryId || home.stockNumber)
      );
    }
    if (home && inLocal) {
      return (
        '/homes/' +
        (home.isAvailableFloorPlan
          ? `model/${home.modelNumber}`
          : home.serialNumber || home.inventoryId || home.stockNumber)
      );
    }
  };

  return (
    <App
      dealer={dealer}
      homes={homes}
      isLoading={isLoading}
      home={home}
      match={match}
    >
      {!home && <MissingHome dealer={dealer} homes={homes} />}
      {home && (
        <HomeDetailsStyles>
          <Helmet>
            <title>Home Details | {formatDealerName(dealer?.dealerName)}</title>
            <meta
              property="og:site_name"
              content={modelNameTransformer(
                homeObject && homeObject.description
              )}
            />
            <meta property="og:url" content={buildHomeUrl(hostname, home)} />
            <meta property="og:type" content="article" />
            <meta
              property="og:title"
              content={modelNameTransformer(
                homeObject && homeObject.description
              )}
            />
            <meta
              property="og:description"
              content={
                modelNameTransformer(homeObject && homeObject.description) +
                ', ' +
                (homeObject && homeObject.beds + ' bedroom') +
                ', ' +
                (homeObject && homeObject.baths + ' bathroom') +
                ' home is available for immediate purchase. Come see this beautiful home.'
              }
            />
            <meta
              property="og:image"
              content={
                home?.thumbnailImage.reference + '?width=1200&height=630'
              }
            />
            <meta
              property="og:secure_image"
              content={
                home?.thumbnailImage.reference + '?width=1200&height=630'
              }
            />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="fb:app_id" content="143883962848442" />
          </Helmet>
          <HomeDetailsStructuredData
            homeUrl={
              typeof window !== 'undefined' ? window?.location?.href : null
            }
            home={home}
            homeDescription={formatDescription(
              home.lineDescription,
              modelNameTransformer(homeObject.description),
              home.beds,
              home.baths,
              home.squareFeet
            )}
            dealerLogo={dealer?.vectorLogoReference}
            homeCenterName={formatDealerName(dealer?.dealerName)}
          />
          <NavBar
            sticky={isSticky}
            homeObject={homeObject}
            brochureLink={`${buildHomePath(home)}/brochure`}
            onRequestClick={() => {
              setShowRequestModal(!showRequestModal) +
                fireSegmentEvent('CTA Clicked', {
                  isNav: false,
                  type: 'button',
                  text: 'REQUEST INFO',
                  eventContext: 'home_details_sticky_nav_request',
                });
            }}
            onShowScheduleClick={() => {
              setShowScheduleModal(!showScheduleModal) +
                fireSegmentEvent('CTA Clicked', {
                  isNav: false,
                  type: 'button',
                  text: 'SCHEDULE A TOUR',
                  eventContext: 'home_details_sticky_nav_schedule',
                });
            }}
            dealer={dealer}
            homes={homes}
          />
          <div className="centered-page-content-container">
            {homeObject && (
              <>
                {history?.location?.state?.prevPath &&
                  history?.location?.state?.prevPath.includes('/portal') && (
                    <div className="back-btn-portal" onClick={history.goBack}>
                      <p>
                        <img src={backBtnArrow} alt="back button" />
                        Back to{' '}
                        {findPathName(history?.location?.state?.prevPath)}
                      </p>
                    </div>
                  )}
                <div className="top-area-container">
                  <div className="card-info-area" ref={element}>
                    <div className="sticker-container">
                      {homeObject.stickers.map((sticker, i) => {
                        if (
                          sticker &&
                          sticker.title &&
                          sticker.title == 'SALE' &&
                          dealer?.websiteTemplateDescription == 'BuilderModel'
                        ) {
                          return null;
                        }
                        return sticker && sticker.title ? (
                          <div
                            className={
                              sticker.className
                                ? 'sticker label ' + sticker.className
                                : 'sticker label'
                            }
                            key={i}
                          >
                            <div className="in-stock-dot">&nbsp;</div>{' '}
                            {sticker.title}
                          </div>
                        ) : null;
                      })}
                    </div>

                    {homeObject &&
                      homeObject.description &&
                      !homeObject.isLand && (
                        <h2 className="card-heading">
                          {modelNameTransformer(homeObject.description)}
                        </h2>
                      )}

                    {homeObject && homeObject.isLand && (
                      <h2 className="card-heading">
                        {homeObject.address1 !== '' &&
                        homeObject.address1 !== null
                          ? modelNameTransformer(fullAddress())
                          : modelNameTransformer(homeObject.description)}
                      </h2>
                    )}

                    <div className="home-specs-container">
                      <div className="price">{generatePriceOverride()}</div>
                      <div className="spacer"></div>
                      <p className="card-home-specs caption">
                        {homeObject && homeObject.beds && (
                          <span>
                            <img src={bed} alt="bed icon" />
                            {homeObject.beds > 1
                              ? homeObject.beds + ' beds'
                              : homeObject.beds + ' bed'}
                          </span>
                        )}

                        {homeObject && homeObject.baths && (
                          <span>
                            <img src={bath} alt="bath icon" />
                            {homeObject.baths > 1
                              ? homeObject.baths + ' baths'
                              : homeObject.baths + ' bath'}
                          </span>
                        )}

                        {homeObject && homeObject.squareFeet && (
                          <span>
                            <img src={homeIcon} alt="house icon" />
                            {formatNumberWithCommas(homeObject.squareFeet) +
                              ' sq. ft.'}
                          </span>
                        )}
                        {(homeObject.isLand || homeObject.isOnLand) &&
                          homeObject.acres !== null &&
                          homeObject.acres !== 0 && (
                            <span>
                              <img src={mountains} alt="mountain icon" />
                              {homeObject.acres !== 1
                                ? `${homeObject.acres} acres`
                                : `${homeObject.acres} acre`}
                            </span>
                          )}
                      </p>
                    </div>
                  </div>
                  <div className="request-info">
                    <Button
                      className="request-info-btn ga-request-info"
                      onClick={() => {
                        setShowRequestModal(!showRequestModal);
                        fireSegmentEvent('CTA Clicked', {
                          isNav: false,
                          type: 'button',
                          text: 'REQUEST INFO',
                          eventContext: 'home_details_hero_request',
                        });
                      }}
                      automationId="homeDetailsButtonOne"
                    >
                      REQUEST INFO
                    </Button>
                    <div className="link-container">
                      <button
                        className="ga-schedule-main"
                        onClick={() => {
                          setShowScheduleModal(!showScheduleModal);
                          fireSegmentEvent('CTA Clicked', {
                            isNav: false,
                            type: 'button',
                            text: 'SCHEDULE A TOUR',
                            eventContext: 'home_details_primary_schedule',
                          });
                        }}
                      >
                        <Clock
                          color={Colors.primary.claytonBlue.standard}
                          width="16px"
                          height="16px"
                        />
                        SCHEDULE A TOUR
                      </button>
                      <Link
                        className="ga-brochure-main"
                        target="_blank"
                        to={{
                          pathname: `${buildHomePath(home)}/brochure`,
                          state: { dealer, homes },
                        }}
                        onClick={() => {
                          fireSegmentEvent('CTA Clicked', {
                            type: 'button',
                            eventContext: 'home_details_top_brochure',
                            value: buildHomePath(home) + `/brochure`,
                          });
                        }}
                      >
                        <ArrowDown
                          color={Colors.primary.claytonBlue.standard}
                          width="16px"
                          height="16px"
                        />
                        BROCHURE
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="home-image-area">
              {homeObject && (
                <>
                  {home && home.virtualTour && !home.isOnLand && (
                    <a
                      tabIndex="0"
                      href={home.virtualTour}
                      target="_blank"
                      className="ga-three-d-tour three-d-tour-desktop"
                      onClick={() => {
                        homeDetailsSegmentEvent();
                      }}
                    >
                      <img src={cubeIcon} alt="cube icon" />
                      TAKE 3D TOUR
                    </a>
                  )}

                  {accountContext?.state.isAuthenticated ? (
                    <span
                      className="favorite-button"
                      onClick={() =>
                        accountContext?.actions?.toggleFavorite(
                          homeObject?.modelNumber,
                          homeObject?.serialNumber
                        )
                      }
                    >
                      {favoriteCheck(homeObject) ? <Heart /> : <HeartOutline />}
                    </span>
                  ) : (
                    <Link
                      className="favorite-button"
                      to={{
                        pathname: '/myhome-account-login',
                        state: {
                          redirectPage: redirectPath,
                          queuedFavorite: {
                            modelNumber: homeObject?.modelNumber,
                            serialNumber: homeObject?.serialNumber,
                          },
                        },
                      }}
                    >
                      <HeartOutline />
                    </Link>
                  )}

                  <Carousel
                    showArrows={width > 1100}
                    autoPlay={false}
                    showIndicators={false}
                    showStatus={false}
                    renderThumbs={() =>
                      homeObject.images.map((image) => {
                        return (
                          <img
                            key={image.reference}
                            className={`${
                              image.imageTypeAcronym.toLowerCase() === 'flp'
                                ? 'thumbs-flp'
                                : ''
                            }`}
                            src={`${image.reference}?fm=webp&width=260`}
                            alt={image?.caption}
                          />
                        );
                      })
                    }
                    swipeable={true}
                    emulateTouch={true}
                    infiniteLoop={true}
                    useKeyboardArrows={true}
                    thumbWidth={
                      width <= 375
                        ? 115
                        : width <= 562
                        ? 102
                        : width <= 1100
                        ? 120
                        : 129
                    }
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                      hasPrev && (
                        <button
                          type="button"
                          onClick={onClickHandler}
                          title={label}
                          className="slider-btns slider-btn-prev"
                        >
                          <img src={nextBtn} alt="next slide" />
                        </button>
                      )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                      hasNext && (
                        <button
                          type="button"
                          onClick={onClickHandler}
                          title={label}
                          style={{ ...arrowStyles, right: 15 }}
                          className="slider-btns slider-btn-next"
                        >
                          <img src={nextBtn} alt="next slide" />
                        </button>
                      )
                    }
                  >
                    {homeObject.images.map((image, i) => {
                      return (
                        <div key={image.reference} className="slide-inner">
                          {isMobile ? (
                            <>
                              <div className="tools">
                                <button
                                  className="zoom-in"
                                  onClick={() => {
                                    setSlideOpen(true);
                                    setSlideIndex(i);
                                  }}
                                >
                                  <img src={ZoomIn} alt="zoom in" />
                                </button>
                              </div>
                              <img
                                src={`${image.reference}?fm=webp&width=1015`}
                                alt={image?.caption}
                              />
                            </>
                          ) : (
                            <img
                              className={`${
                                image.imageTypeAcronym.toLowerCase() === 'flp'
                                  ? 'desktop-image flp'
                                  : 'desktop-image'
                              }`}
                              src={`${image.reference}?fm=webp&width=1015`}
                              alt={image?.caption}
                            />
                          )}
                        </div>
                      );
                    })}
                    {homeObject.images.map((image, i) => {
                      return (
                        <div className="slide-inner" key={image.reference}>
                          {isMobile ? (
                            <>
                              <div className="tools">
                                <button
                                  className="zoom-in"
                                  onClick={() => {
                                    setSlideOpen(true);
                                    setSlideIndex(i);
                                  }}
                                >
                                  <img src={ZoomIn} alt="zoom in" />
                                </button>
                              </div>
                              <img
                                src={`${image.reference}?fm=webp&width=1015`}
                                alt={image?.caption}
                              />
                            </>
                          ) : (
                            <img
                              className={`${
                                image.imageTypeAcronym.toLowerCase() === 'flp'
                                  ? 'desktop-image flp'
                                  : 'desktop-image'
                              }`}
                              src={`${image.reference}?fm=webp&width=1015`}
                              alt={image?.caption}
                            />
                          )}
                        </div>
                      );
                    })}
                  </Carousel>
                  <div
                    className={
                      home && home.virtualTour && !home.isOnLand
                        ? 'home-details-extra-with-tour'
                        : 'home-details-extra'
                    }
                  >
                    {home && home.virtualTour && !home.isOnLand && (
                      <a
                        tabIndex="0"
                        href={home.virtualTour}
                        target="_blank"
                        className="ga-three-d-tour three-d-tour"
                        onClick={() => {
                          homeDetailsSegmentEvent();
                        }}
                      >
                        <img src={cubeIcon} alt="cube icon" />
                        TAKE 3D TOUR
                      </a>
                    )}
                    <p className="image-disclaimer">
                      *Images may show options not included in base price
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="request-info-mobile">
              <Button
                className="request-info-btn ga-request-info"
                onClick={() => {
                  setShowRequestModal(!showRequestModal);
                  fireSegmentEvent('CTA Clicked', {
                    isNav: false,
                    type: 'button',
                    text: 'REQUEST INFO',
                    eventContext: 'home_details_hero_request',
                  });
                }}
                automationId="homeDetailsButtonTwo"
              >
                REQUEST INFO
              </Button>
              <div className="link-container">
                <button
                  className="ga-schedule-main"
                  onClick={() => {
                    setShowScheduleModal(!showScheduleModal);
                    fireSegmentEvent('CTA Clicked', {
                      isNav: false,
                      type: 'button',
                      text: 'SCHEDULE A TOUR',
                      eventContext: 'home_details_primary_schedule',
                    });
                  }}
                >
                  <Clock
                    color={Colors.primary.claytonBlue.standard}
                    width="16px"
                    height="16px"
                  />
                  SCHEDULE A TOUR
                </button>
                <Link
                  className="ga-brochure-main"
                  target="_blank"
                  to={{
                    pathname: `${buildHomePath(home)}/brochure`,
                    state: { dealer, homes },
                  }}
                  onClick={() => {
                    fireSegmentEvent('CTA Clicked', {
                      isNav: false,
                      type: 'button',
                      text: 'BROCHURE',
                      eventContext: 'home_details_top_brochure',
                    });
                  }}
                >
                  <ArrowDown
                    color={Colors.primary.claytonBlue.standard}
                    width="16px"
                    height="16px"
                  />
                  BROCHURE
                </Link>
              </div>
            </div>
            {home && homeObject && home.description && (
              <div className="home-details-overview">
                <h4>Overview</h4>
                <p>{home.description}</p>
              </div>
            )}
            {home && !home.description && home.lineDescription && (
              <div className="home-details-overview">
                <h4>Overview</h4>
                <p>
                  {formatDescription(
                    home.lineDescription,
                    modelNameTransformer(homeObject.description),
                    home.beds,
                    home.baths,
                    home.squareFeet
                  )}
                </p>
              </div>
            )}
            {home && (home.isLand || home.isOnLand) && fullAddress() && (
              <div className="home-details-map">
                <h4>Location</h4>
                <p>{fullAddress()}</p>
                <GoogleMap dealer={dealer} home={home} />
              </div>
            )}
            {home &&
              (home.modelFeatures.length > 0 ||
                home.inventoryFeatures.length > 0) && (
                <div className="home-details-features">
                  <h4>Features</h4>
                  <ul>
                    {allFeatures().map((feature) => {
                      return (
                        <FeatureListItem feature={feature} key={feature} />
                      );
                    })}
                  </ul>
                </div>
              )}
            {home && !home.isLand && home.floorPlanImage.reference && (
              <div className="home-details-floorplan">
                <h4>Floor Plan</h4>
                <Controls />
                <img
                  src={home.floorPlanImage.reference + '?fm=webp'}
                  onClick={() => setFlpOpen(true)}
                />
                <Lightbox
                  open={flpOpen}
                  close={() => setFlpOpen(false)}
                  slides={[
                    {
                      src: home.floorPlanImage.reference + '?fm=webp',
                    },
                  ]}
                  plugins={[Zoom]}
                  render={{
                    buttonPrev: () => null,
                    buttonNext: () => null,
                  }}
                  carousel={{
                    finite: true,
                  }}
                  zoom={{
                    scrollToZoom,
                    zoomInMultiplier,
                    maxZoomPixelRatio,
                  }}
                />
              </div>
            )}
          </div>
          {dealer?.websiteTemplateDescription !== 'BuilderModel' && (
            <LargeCTA
              key="large-cta"
              buttonText="Explore HouseSmart®"
              buttonURL="/housesmart"
              backgroundColor="blue"
              gaClass="ga-home-details-housesmart-btn"
              retailEventLabel="home_details_housesmart"
              linkState={(dealer, homes)}
              smallText={'Homes built with you in mind'}
              largeText={
                <p className="h2 large-text">
                  HouseSmart® is how we approach everything we do.
                </p>
              }
              backgroundImage={{
                source: require('../../images/webp/blueCTABackground.webp'),
                alt: 'blue background image',
              }}
              headlineWidth={'450px'}
              backgroundPosition={'top right'}
              absoluteLogo={houseSmartLogo}
              absoluteLogoPositionRight={true}
              dealer={dealer}
              homes={homes}
            />
          )}

          <div className="centered-page-content-container">
            {dealer?.websiteTemplateDescription !== 'BuilderModel' && (
              <LongFormCTA
                largeText="We accept trades!"
                smallText="Find a new home you love? Contact us for more information about our trade-in requirements and to schedule your free trade evaluation."
                buttonText="Learn More"
                buttonUrl="/special-offers/trade-in"
                gaClass="ga-learn-trades"
                backgroundImage={LongFormCtaBackground}
                dealer={dealer}
                homes={homes}
                retailEventLabel="home_details_trade"
              />
            )}
            {home &&
              !home.isOnLand &&
              !home.isOnThirdPartyLand &&
              !home.isLand && (
                <div className="home-details-next-step">
                  <h2>Take the next step</h2>
                  <ul>
                    <li>
                      <Link
                        className="ga-brochure-next-steps"
                        target="_blank"
                        to={{
                          pathname: `${buildHomePath(home)}/brochure`,
                          state: { dealer, homes },
                        }}
                        onClick={() => {
                          fireSegmentEvent('CTA Clicked', {
                            eventContext: 'home_details_3up_ctas_brochure',
                            type: 'button',
                            value: buildHomePath(home) + `/brochure`,
                          });
                        }}
                      >
                        <ArrowDown color="#0075c9" width="33px" height="33px" />
                        <span>View brochure</span>
                      </Link>
                    </li>
                    <li>
                      <button
                        className="ga-request-info-next-steps"
                        onClick={() => {
                          setShowRequestModal(!showRequestModal) +
                            fireSegmentEvent('CTA Clicked', {
                              isNav: false,
                              type: 'button',
                              text: 'Request More Info',
                              eventContext: 'home_details_3up_ctas_request',
                            });
                        }}
                      >
                        <EnvelopeClosed
                          color="#0075c9"
                          width="33px"
                          height="33px"
                        />
                        <span>Request More Info</span>
                      </button>
                    </li>
                    <li>
                      <button
                        className="ga-schedule-next-steps"
                        onClick={() => {
                          setShowScheduleModal(!showScheduleModal) +
                            fireSegmentEvent('CTA Clicked', {
                              isNav: false,
                              type: 'button',
                              text: 'Schedule a Visit',
                              eventContext: 'home_details_3up_ctas_schedule',
                            });
                        }}
                      >
                        <Clock color="#0075c9" width="33px" height="33px" />
                        <span>Schedule a visit</span>
                      </button>
                    </li>
                  </ul>
                </div>
              )}
          </div>

          {home &&
            (home.isLand || home.isOnLand) &&
            home.brokerageName !== '' &&
            home.brokerageName !== null && (
              <div className="home-details-brokerage-info">
                <div className="brokerage-container">
                  <div className="brokerage-info">
                    <h2>Learn More About this Property</h2>
                    <h4>{home.brokerageName}</h4>
                    {home.brokeragePhone && (
                      <p>
                        Brokerage Phone:{' '}
                        <a href={'tel:' + home.brokeragePhone}>
                          {formatPhoneNumberFromNumber(home.brokeragePhone)}
                        </a>
                      </p>
                    )}
                    {home.brokerageEmail && (
                      <p>
                        Listing Agent Email:{' '}
                        <a href={'mailto:' + home.brokerageEmail}>
                          {home.brokerageEmail}
                        </a>
                      </p>
                    )}
                  </div>
                  <div className="brokerage-disclaimer">
                    Copyright &copy; {year} Knoxville Area Association of
                    Realtors &#174;. All rights reserved. All information
                    provided by the listing agent/broker is deemed reliable but
                    is not guaranteed and should be independently verified.
                  </div>
                </div>
              </div>
            )}
          {home.isOnLand ? (
            <div className="price-disclaimer">
              *Sales price does not include other costs such as taxes, title
              fees, insurance premiums, filing or recording fees, improvements
              to the land or home, community or homeowner association fees, or
              any other items not shown on your Sales Agreement, Retailer
              Closing Agreement and related documents (your SA/RCA). If you
              purchase a home, your SA/RCA will show the details of your
              purchase. Artists’ renderings of homes are only representations
              and actual home may vary. Floor plan dimensions are approximate
              and based on length and width measurements from exterior wall to
              exterior wall. We invest in continuous product and process
              improvement. All home series, floor plans, specifications,
              dimensions, features, materials, and availability shown on this
              website are subject to change.
            </div>
          ) : (
            <div className="price-disclaimer">
              *Advertised starting sales prices are for the home only. Delivery
              and installation costs are not included unless otherwise stated.
              Starting prices shown on this website are subject to change, see
              your local Home Center for current and specific home and pricing
              information. Sales price does not include other costs such as
              taxes, title fees, insurance premiums, filing or recording fees,
              land or improvements to the land, optional home features, optional
              delivery or installation services, wheels and axles, community or
              homeowner association fees, or any other items not shown on your
              Sales Agreement, Retailer Closing Agreement and related documents
              (your SA/RCA). If you purchase a home, your SA/RCA will show the
              details of your purchase. Homes available at the advertised sales
              price will vary by retailer and state. Artists’ renderings of
              homes are only representations and actual home may vary. Floor
              plan dimensions are approximate and based on length and width
              measurements from exterior wall to exterior wall. We invest in
              continuous product and process improvement. All home series, floor
              plans, specifications, dimensions, features, materials, and
              availability shown on this website are subject to change.
            </div>
          )}
          <RecentlyViewedHomes dealer={dealer} homes={homes} />
          {homeObject && (
            <XLCTA
              largeText="Find Your Perfect Home"
              home={home}
              homeTitle={modelNameTransformer(homeObject.description)}
              dealerInfo={dealer}
              retailEventLabelInfo={'home_details_request_form_submit'}
              retailEventLabelSchedule={'home_details_schedule_form_submit'}
              retailEventLabelPhone={'home_details_schedule_form_phone'}
              buttons={[
                {
                  buttonModal: 'information',
                  buttonText: 'Get More Information',
                  gaClass: 'ga-home-details-get-more-information',
                  openForm: 'requestInfo',
                  retailEventLabel: 'footer_dual_cta_request',
                },
                {
                  buttonModal: 'schedule',
                  buttonText: 'Schedule a Tour',
                  gaClass:
                    'ga-home-details-schedule-appointment-footer-dual-cta',
                  openForm: 'scheduleVisit',
                  retailEventLabel: 'footer_dual_cta_schedule',
                },
              ]}
            />
          )}
        </HomeDetailsStyles>
      )}
      {showRequestModal && (
        <Modal onClose={() => setShowRequestModal(false)}>
          <RequestInfoModalForm
            title={modelNameTransformer(homeObject.description)}
            closeFunction={() => {
              setShowRequestModal(false);
              document.documentElement.classList.remove('modal-lock');
            }}
            isHomeDetails={true}
            dealer={dealer}
            home={home}
            retailEventLabelPhone={'home_details_schedule_form_phone'}
            retailEventLabel={'home_details_request_form_submit'}
          />
        </Modal>
      )}

      {showScheduleModal && (
        <Modal onClose={() => setShowScheduleModal(false)}>
          <ScheduleVisitModalForm
            title={modelNameTransformer(homeObject.description)}
            closeFunction={() => {
              setShowScheduleModal(false);
              document.documentElement.classList.remove('modal-lock');
            }}
            isHomeDetails={true}
            dealer={dealer}
            home={home}
            retailEventLabelPhone={'home_details_schedule_form_phone'}
            retailEventLabel={'home_details_schedule_form_submit'}
          />
        </Modal>
      )}
      {slideOpen && (
        <Lightbox
          open={slideOpen}
          // close={() => setSlideOpen(false)}
          close={() => {
            setSlideOpen(false);
            setSlideIndex(0);
          }}
          index={slideIndex}
          slides={[
            {
              src: `${homeObject.images[slideIndex].reference}?fm=webp&width=1015`,
            },
          ]}
          render={{
            buttonPrev: () => null,
            buttonNext: () => null,
          }}
          plugins={[Zoom]}
          zoom={{
            scrollToZoom,
            zoomInMultiplier,
            maxZoomPixelRatio,
          }}
        />
      )}
    </App>
  );
};

HomeDetails.getInitialProps = async ({
  req,
  res,
  match,
  history,
  location,
  ...ctx
}) => {
  let home;
  let homeSerialNumber = match.params.serialNumber;
  let homeModelNumber = match.params.modelNumber;
  let homeStockNumber = match.params.stockNumber;

  const { dealer, homes, hostname } = await parseInitialPropsContext(
    { req, location },
    dealerService,
    homesService
  ).catch((e) => {
    console.error(e);
    return { redirectTo: '/error' };
  });

  if (homeModelNumber) {
    home = homes.filter(
      (home) =>
        home.isAvailableFloorPlan && home.modelNumber === homeModelNumber
    )[0];
  } else {
    home = homes.filter(
      (home) =>
        home.serialNumber === homeSerialNumber ||
        home.stockNumber === homeStockNumber
    )[0];
  }

  return { dealer, homes, home, hostname };
};

export default HomeDetails;
