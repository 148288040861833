import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import useWindowSize from '../../../hooks/useWindowSize';
import { fireSegmentEvent } from '../../../utils';
import OptimizedImage from '../OptimizedImage/OptimizedImage';
import LargeCTAStyles from './LargeCTA.styled';

export const LargeCTA = ({
  smallText,
  largeText,
  disclaimerText,
  buttonText,
  buttonURL,
  aTagLink,
  backgroundColor,
  backgroundImage,
  headlineWidth,
  gaClass,
  dealer,
  homes,
  logo,
  backgroundPosition,
  absoluteLogo,
  absoluteLogoPositionRight,
  absoluteLogoPositionLeft,
  logoText,
  retailEventLabel,
}) => {
  const [width, height] = useWindowSize();
  const isMobile = width <= 1099;
  return (
    <LargeCTAStyles
      backgroundPosition={backgroundPosition}
      absoluteLogoPositionRight={absoluteLogoPositionRight}
    >
      <div className="cta-container">
        <div className={`large-cta ` + `${backgroundColor}-background`}>
          <OptimizedImage
            src={backgroundImage?.source}
            srcSet={backgroundImage?.srcSet}
            alt={backgroundImage?.alt}
            lazy
          />

          <div
            className="content"
            style={{ maxWidth: headlineWidth ? headlineWidth : '385px' }}
          >
            {logo && <img src={logo} className="logo" loading="lazy" />}
            {smallText && <h3 className="small-text">{smallText}</h3>}
            {largeText && <p className="h2 large-text">{largeText}</p>}
            {buttonURL && buttonText && !aTagLink && (
              <Link
                to={{ pathname: buttonURL, state: { dealer, homes } }}
                className={'cta-button ' + (gaClass ? gaClass : '')}
                onClick={() => {
                  fireSegmentEvent('CTA Clicked', {
                    isNav: false,
                    type: 'button',
                    text: buttonText ? buttonText : '',
                    eventContext: retailEventLabel ? retailEventLabel : '',
                  });
                }}
              >
                {buttonText}
              </Link>
            )}
            {buttonURL && buttonText && aTagLink && (
              <a
                href={buttonURL}
                className={'cta-button ' + (gaClass ? gaClass : '')}
              >
                {buttonText}
              </a>
            )}
          </div>
          {absoluteLogo && (
            <div className="absolute-logo-wrap">
              <img
                className="absolute-logo"
                src={absoluteLogo}
                loading="lazy"
                alt="promo small logo"
              />

              {logoText && <p className="logo-text">{logoText}</p>}
            </div>
          )}
        </div>
      </div>
    </LargeCTAStyles>
  );
};

LargeCTA.props = {
  smallText: PropTypes.string,
  largeText: PropTypes.string,
  headlineWidth: PropTypes.string,
  buttonText: PropTypes.string,
  buttonURL: PropTypes.string,
  aTagLink: PropTypes.bool,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundPosition: PropTypes.string,
  gaClass: PropTypes.string,
  logoText: PropTypes.string,
  retailEventLabel: PropTypes.string,
};

LargeCTA.defaultProps = {
  backgroundColor: 'blue',
};

export default LargeCTA;
