import styled from 'styled-components';

export const StyledWinterPromoThankYou = styled.div`
  .ty-message-banner {
    width: 100%;

    @media only screen and (min-width: 1025px) {
      display: flex;
      justify-content: space-between;
    }
  }
  .loading-block {
    min-height: calc(100vh - 89px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
