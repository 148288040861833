import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors.js';
import Color from '../../../colors';

const ExploreWaysToSaveBannerStyles = styled.div`
  position: relative;
  height: 191px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.backgroundColor || Color.primary.claytonBlue.standard};
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  h2 {
    position: absolute;
    color: ${Colors.primary.white.standard};
    font-size: 36px;
    line-height: 1.2;
    letter-spacing: -0.35px;
    text-align: center;
    max-width: 308px;

    @media screen and (min-width: 768px) {
      font-size: 40px;
      line-height: 1;
      max-width: none;
    }

    @media screen and (min-width: 1025px) {
      font-size: 48px;
    }
  }
`;

export default ExploreWaysToSaveBannerStyles;
