import { formatDealerName } from 'pubweb-smokey/dist/utils/formatters';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useLocation } from 'react-router-dom';
import App from '../../App';
import Colors from '../../colors';
import VideoBackgroundImage from '../../images/promo-images/fall-promo/red-tag-sale-video-background.webp';
import ExploreMoreWaysSaveBackground from '../../images/explore-more-ways-save-background.webp';
import ExploreMoreWaysSaveTabletBackground from '../../images/explore-more-ways-save-background-tablet.webp';
import ExploreMoreWaysSaveMobileBackground from '../../images/explore-more-ways-save-background-mobile.webp';
import * as dealerService from '../../services/dealerService';
import * as homesService from '../../services/homesService';
import { formatDealerBrand, parseInitialPropsContext } from '../../utils';
import { getNewsAndEvents } from '../../Api.js';
import SaleHomeSlider from '../Shared/SaleHomeSlider/SaleHomeSlider';
import HouseSmartPromoGrid from '../Shared/HouseSmartPromoGrid/HouseSmartPromoGrid';
import FeaturedEvent from '../Shared/FeaturedEvent/FeaturedEvent';
import { smoothScroll } from '../Shared/SmokeyTransitional/utils/utils';
import TextContent from '../Shared/TextContent/TextContent';
import Video from '../Shared/Video/Video';
import XLCTA from '../Shared/XLCTA/XLCTA';
import Banner from './Banner';
import FallPromoStyles from './FallPromo.styled';
import PromoDisclaimer from './PromoDisclaimer';
import PromoContent from '../Promo/PromoContent';
import ExploreWaysToSaveBanner from '../Shared/ExploreWaysToSaveBanner/ExploreWaysToSaveBanner.js';

const promoIsInactive = true;

const FallPromo = ({
  dealer,
  homes,
  homesOnSale,
  featuredEvent,
  isLoading,
}) => {
  const [showTy, setShowTy] = useState(false);
  const disclaimerRef = useRef(null);

  const dealerBrand = dealer?.dealerName.split(' ');

  const showThankYou = () => {
    setShowTy(true);
  };

  const isClayton = () => {
    if (dealer?.brandAcronym.toLowerCase() === 'cla') {
      return true;
    }
    return false;
  };

  const handleScroll = () => {
    smoothScroll('#PromoDisclaimer', -90);
  };

  const { pathname, hash, key } = useLocation();

  useLayoutEffect(() => {
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 800);
    }
  }, []);

  if (dealer?.websiteTemplateDescription == 'BuilderModel') {
    return <Redirect to="/" />;
  }
  if (dealer?.websiteTemplateDescription === 'NoSpecialOffers') {
    return <Redirect to="/sale-homes" />;
  }
  if (promoIsInactive) {
    return <Redirect to="/special-offers/go-big-go-home" />;
  }

  return (
    <App dealer={dealer} homes={homes} isLoading={isLoading}>
      <FallPromoStyles>
        <Helmet>
          <title>
            Red Tag Sales Event - Affordable Modern Manufactured, Mobile Homes |{' '}
            {dealer ? formatDealerName(dealer?.dealerName) : ''}
          </title>
          <meta
            name="description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
          <meta
            name="og:description"
            content={`Come visit our Clayton Retailers today to learn more about special offers and promotions available in your area. `}
          />
        </Helmet>
        <Banner
          bannerHeadline={'Save thousands*'}
          bannerSubHeadline={'on select in-stock homes.'}
          bannerDisclaimer={
            '*Sale homes will vary by retailer and state. See retailer for list of eligible homes.'
          }
          formText={
            'Complete this form to receive information about sale homes and future offers.'
          }
          formDisclaimer={
            <>
              By submitting my information, I agree that{' '}
              {isClayton() ? (
                'Clayton'
              ) : (
                <span className="capitalize-first-letter">
                  {dealerBrand[0].toLowerCase()}
                </span>
              )}{' '}
              Homes and its service providers may contact me and may send me
              marketing communications by telephone and text message using
              automated dialing technology to the mobile number I provided in
              this form. See our{' '}
              <a
                href="https://www.claytonhomes.com/account-terms-conditions"
                target="_blank"
              >
                Terms &amp; Conditions
              </a>
              , which includes opt-out instructions. Message and data rates may
              apply. I also agree that{' '}
              {isClayton() ? (
                'Clayton'
              ) : (
                <span className="capitalize-first-letter">
                  {dealerBrand[0].toLowerCase()}
                </span>
              )}{' '}
              Homes and its service providers may send me marketing e-mails,
              which I may unsubscribe from at any time. Consent not required for
              purchase. Please view our{' '}
              <a href="https://www.claytonhomes.com/privacy/" target="_blank">
                Privacy Policies
              </a>
              .
            </>
          }
          dealer={dealer}
          homes={homes}
          showThankYouCallBack={showThankYou}
          showTy={showTy}
          isClayton={isClayton}
        />
        <TextContent
          smallHeadline={`red tag sale`}
          largeHeadline={
            'It’s the best time of year to find a beautiful, affordable home.'
          }
          content={
            <>
              For a limited time, we're reducing prices on our modern
              manufactured homes and making the dream of owning a new home even
              more within reach. Save thousands on select in-stock homes during
              our annual Red Tag Sales Event!
            </>
          }
          smallDisclaimer={
            '*Sale homes will vary by retailer and state. See local retailer for list of eligible homes.'
          }
          backgroundColor={Colors.secondary.grey6.standard}
          mobileBackgroundColor={Colors.secondary.grey6.standard}
          aboveVideoContent={false}
          isPromo={true}
        />
        {homesOnSale && homesOnSale.length > 0 && (
          <SaleHomeSlider
            sliderId="promoSlider"
            largeHeadline={
              !promoIsInactive ? 'Featured Sale Homes' : 'Explore Homes'
            }
            saleHomes={homesOnSale}
            buttonText={'View All Sale Homes'}
            buttonURL={'/sale-homes'}
            gaClass={'ga-seasonal-campaign-view-sale-homes'}
            retailEventLabel={'campaign_sale_homes_view_all'}
            dealer={dealer}
          />
        )}
        <Video
          gaClass={'ga-seasonal-campaign-video'}
          retailEventLabel={'campaign_video'}
          image={{ source: VideoBackgroundImage, alt: 'The Red Tag Sale' }}
          videoID={isClayton() ? 'q3y85hn5de' : 'tssi6e1jtv'}
          wistiaOptions={'silentAutoPlay=false autoPlay=true'}
          noSaleHomes={true}
        />
        <HouseSmartPromoGrid hideBottomRow={true} hideDisclaimer={true} />
        <ExploreWaysToSaveBanner
          headline={'Explore More Ways to Save'}
          backgroundImages={{
            desktop: ExploreMoreWaysSaveBackground,
            tablet: ExploreMoreWaysSaveTabletBackground,
            mobile: ExploreMoreWaysSaveMobileBackground,
          }}
        />
        {featuredEvent && (
          <FeaturedEvent
            buttonText={'Learn More'}
            smallHeadline={'Upcoming Events'}
            featuredEvent={featuredEvent}
            isSpecialOffers={true}
          />
        )}
        <PromoContent
          isSeasonalCampaign={true}
          formatDealerName={formatDealerName}
          dealer={dealer}
          homes={homes}
        />

        {dealer && (
          <XLCTA
            phoneNumber={dealer.phoneNumber}
            customCallText={'Find Your Perfect Home'}
            gaPhoneClass={'ga-seasonal-campaign-phone-number'}
            buttons={[
              {
                buttonText: 'Get More Information',
                buttonURL: '/contact-us',
                gaClass: 'ga-seasonal-campaign-get-more-information',
                openForm: 'requestInfo',
                retailEventLabel: 'footer_dual_cta_request',
              },
              {
                buttonText: 'Schedule a Visit',
                buttonURL: '/contact-us',
                gaClass: 'ga-seasonal-campaign-schedule-a-visit',
                openForm: 'scheduleVisit',
                retailEventLabel: 'footer_dual_cta_schedule',
              },
            ]}
          />
        )}
      </FallPromoStyles>
    </App>
  );
};

FallPromo.getInitialProps = async (ctx) => {
  let featuredEvent;
  const { dealer, homes, homesOnSale } = await parseInitialPropsContext(
    ctx,
    dealerService,
    homesService
  );

  try {
    const result = await getNewsAndEvents(dealer?.dealerId);
    featuredEvent = result.filter((event) => event.isFeatured)[0];
  } catch (e) {
    console.error(e);
  }

  return { dealer, homes, homesOnSale, featuredEvent };
};

export default FallPromo;
